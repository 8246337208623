type Rect = { x1: number; x2: number; y1: number; y2: number }

function boundingRectToCoords(r: DOMRect): Rect {
  return {
    x1: r.left,
    x2: r.left + r.width,
    y1: r.top,
    y2: r.top + r.height,
  }
}

function transformRect(r: Rect) {
  return {
    x: [r.x1, r.x2].sort((a, b) => a - b) as [number, number],
    y: [r.y1, r.y2].sort((a, b) => a - b) as [number, number],
  }
}

function getIntersectingRectangle(rect1: Rect, rect2: Rect): Rect | null {
  const r1 = transformRect(rect1)
  const r2 = transformRect(rect2)

  const noIntersect =
    r2.x[0] > r1.x[1] || r2.x[1] < r1.x[0] || r2.y[0] > r1.y[1] || r2.y[1] < r1.y[0]

  return noIntersect
    ? null
    : {
        x1: Math.max(r1.x[0], r2.x[0]), // _[0] is the lesser,
        y1: Math.max(r1.y[0], r2.y[0]), // _[1] is the greater
        x2: Math.min(r1.x[1], r2.x[1]),
        y2: Math.min(r1.y[1], r2.y[1]),
      }
}

function getArea(r1: Rect) {
  const width = r1.x2 - r1.x1
  const height = r1.y2 - r1.y1

  return width * height
}

export function getIntersectionArea(boundingRect1: DOMRect, boundingRect2: DOMRect) {
  const intersectingRectangle = getIntersectingRectangle(
    boundingRectToCoords(boundingRect1),
    boundingRectToCoords(boundingRect2)
  )
  if (!intersectingRectangle) return 0
  return getArea(intersectingRectangle)
}
