import type { AutoshipFrequencyOption, AutoshipFrequencyOptions } from '../../constants/types'

/**
 * A utility function to flatten Autoship frequency JSON list into a single array of
 * Autoship frequencies.
 *
 * @param OPTIONS - a JSON object of Autoship frequencies options
 * @returns a flattened array of Autoship frequencies options
 */
export function flattenAutoshipFrequencyOptions(OPTIONS: AutoshipFrequencyOptions) {
  return Object.entries(OPTIONS).reduce(
    (acc: Array<AutoshipFrequencyOption>, [_key, optionSet]) => [...acc, ...optionSet],
    []
  )
}

/**
 * A utility function to find an Autoship frequency option via a string. It searches for a
 * match by checking if an option's `uuid` equals the `selectedUUID`.
 *
 * @param OPTIONS - a JSON object of Autoship frequencies options
 * @param selectedUUID - the selected UUID to find
 * @returns a single Autoship option or undefined
 */
export function findAutoshipFrequencyOptionByUUID(
  OPTIONS: AutoshipFrequencyOptions,
  selectedUUID: string
) {
  return flattenAutoshipFrequencyOptions(OPTIONS).find((option) => option.uuid === selectedUUID)
}
