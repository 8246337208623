import type { RefObject } from 'react'
import { useCallback, useRef } from 'react'

export type FormFocusRef = RefObject<HTMLFormElement>

/**
 * A reusable hook that will focus on the first `input` within a `form` element after a
 * mutation — a contextual state change — causes the entire DOM tree to be re-rendered.
 *
 * Common use cases might be where we're focus trapping a component and we need to make
 * sure that the last known focused element isn't lost after the DOM tree re-renders.
 *
 * To use this hook, simply apply the `formRef` to a `form` element and invoke
 * the `applyFormFocus` callback function after the DOM tree has finished re-rendering.
 *
 * @returns a `formRef` ref and a `applyFormFocus` callback function
 * @example ```const { formRef, applyFormFocus } = useFormInputFocus()```
 */
export const useFormInputFocus = () => {
  const formRef = useRef<HTMLFormElement | null>(null)

  const applyFormFocus = useCallback(() => {
    if (formRef.current) {
      const firstInput: HTMLInputElement = Object.values(formRef.current).find(
        ({ tagName }) => tagName === 'INPUT'
      )
      firstInput.focus()
    }
  }, [])

  return { formRef, applyFormFocus }
}
