import clsx from 'clsx'
import dynamic from 'next/dynamic'
import type { ChangeEvent, ReactNode } from 'react'
import { forwardRef } from 'react'
import useCustomerHasAutoshipSubscriptions from '../../../hooks/useCustomerHasAutoshipSubscriptions'
import type { FormFocusRef } from '../../../hooks/useFormInputFocus'
import ANALYTICS_ACTIONS from '../../../lib/constants/ANALYTICS_ACTIONS'
import AUTOSHIP from '../../../lib/constants/AUTOSHIP'
import { featureFlags } from '../../../lib/publicConfig'
import { formatCurrency } from '../../../lib/utils/currency'
import { formatCurrencyWithSuperscriptCents } from '../../../lib/utils/formatCurrencyWithSuperscriptCents'
import calculateOrderDeliveryDate from '../../../lib/utils/pageType/product/calculateOrderDeliveryDate'
import calculateUnitPrice from '../../../lib/utils/pageType/product/calculateUnitPrice'
import type { DropdownOption, OptionsList } from '../../global/form/dropdown/OptionDropdown'
import { OptionDropdown } from '../../global/form/dropdown/OptionDropdown'
import { FormError } from '../../global/form/FormError'
import StandardIcon from '../../global/icons/StandardIcon'
import LegacyExclamationIcon from '../../global/legacy/icons/LegacyExclamationIcon'
import type { AddToCartComponentProps } from './AddToCartComponent/AddToCartComponent'

const StickyCTAHeader = dynamic(() => import('./StickyCTAHeader'), { ssr: false })

export type PurchaseTypeCTAButtonsProps = {
  addingToCart: boolean
  buttonLabel?: string
  className?: string
  disabled?: boolean
  error?: string | null
  loadingSubscriptionPlans?: boolean
  quantity: string
  min?: number
  max?: number
  onQuantityChange: (quantity: string) => void
}

export interface AddToCartFormProps
  extends Omit<AddToCartComponentProps, 'product' | 'isAutoshipRecurring' | 'children'>,
    PurchaseTypeCTAButtonsProps {
  autoshipFrequencyOptionsList?: OptionsList
  enableCtaButtons?: boolean
  selectedFrequency?: DropdownOption
  selectedPurchaseType: string
  onPurchaseTypeChange: (event: ChangeEvent<HTMLInputElement>) => void
  onSelectedFrequencyValueChange?: (selectedFrequency: DropdownOption) => void
  onSubmit: () => void
  children?: ReactNode
  discountedPriceDetails?: {
    total: number
    percentage: number
  }
}

export type CartRadioInputProps = Omit<AddToCartFormProps, 'onSubmit' | 'hideAutoshipPricing'> & {
  children?: ReactNode
  id: string
  title: string
  selectedPurchaseType: string
  unitPrice?: number
  discountedPriceDetails?: {
    total: number
    percentage: number
  }
}

export type autoshipPricingAndMessageProps = Pick<
  AddToCartComponentProps,
  'autoshipDiscounts' | 'price' | 'productHasAutoshipRecurringDiscount' | 'binPickingNumber'
> & {
  isSelectedAutoshipPurchaseType: boolean
  showInitialDiscount: boolean
}

function autoshipPricingAndMessage({
  autoshipDiscounts,
  binPickingNumber,
  isSelectedAutoshipPurchaseType,
  price,
  productHasAutoshipRecurringDiscount,
  showInitialDiscount,
}: autoshipPricingAndMessageProps) {
  let autoshipMessage = isSelectedAutoshipPurchaseType
    ? 'Add to Autoship for easy, recurring deliveries'
    : ''
  let adjustedPrice = price

  if (isSelectedAutoshipPurchaseType && showInitialDiscount) {
    adjustedPrice = autoshipDiscounts?.firstDiscount.total
    autoshipMessage = `Save ${autoshipDiscounts?.firstDiscount.percentage}% now on 1st Autoship*`

    // 30% initial discount and 5% recurring discount
    if (productHasAutoshipRecurringDiscount) {
      autoshipMessage += `, ${autoshipDiscounts?.reccuringDiscount.percentage}% on repeat orders`
    }
  } else if (isSelectedAutoshipPurchaseType && productHasAutoshipRecurringDiscount) {
    // 5% discount off price, 5% discount message
    adjustedPrice = autoshipDiscounts?.reccuringDiscount.total
    autoshipMessage = `Add to Autoship to save ${autoshipDiscounts?.reccuringDiscount.percentage}% on repeat orders`
  }

  const unitPrice = calculateUnitPrice({
    bin_picking_number: binPickingNumber,
    price: adjustedPrice,
  })

  return {
    adjustedPrice,
    unitPrice,
    message: autoshipMessage,
  }
}

function PurchaseTypeCTAButtons({
  addingToCart,
  buttonLabel = 'Add to Cart',
  className,
  disabled = false,
  error,
  loadingSubscriptionPlans,
  max,
  min = 1,
  onQuantityChange,
  quantity,
}: PurchaseTypeCTAButtonsProps) {
  const submitDisabled = loadingSubscriptionPlans || disabled || !quantity

  const handleQuantityChange = (increment: number) => {
    const quantityNum = parseInt(quantity, 10)
    if (quantityNum) {
      onQuantityChange(Math.max(quantityNum + increment, min).toString())
    } else {
      onQuantityChange(min.toString())
    }
  }

  return (
    <>
      <div className={clsx('flex space-x-4 lg:flex-col lg:space-x-0 lg:space-y-2', className)}>
        <div className="flex h-12 flex-1 lg:h-11 lg:flex-initial">
          <label htmlFor="quantity" className="sr-only">
            Quantity
          </label>
          <button
            type="button"
            onClick={() => handleQuantityChange(-1)}
            className="h-full min-w-[2.75rem] flex-1 rounded-bl rounded-tl border border-yellow-dark bg-yellow-dark bg-opacity-5 text-2xl leading-none"
          >
            &#8722;
          </button>
          <input
            id="quantity"
            className="hide-spin h-full w-full min-w-0 flex-1 rounded-none border-b border-t border-yellow-dark p-1 text-center text-2xl"
            type="number"
            min={min}
            max={max}
            value={quantity}
            onChange={(event) => onQuantityChange(event.target.value)}
            step={1}
            required
          />
          <button
            type="button"
            className="h-full min-w-[2.75rem] flex-1 rounded-br rounded-tr border border-yellow-dark bg-yellow-dark bg-opacity-5 text-2xl leading-none"
            onClick={() => handleQuantityChange(1)}
          >
            &#43;
          </button>
        </div>
        <div className="flex-1 lg:flex-initial">
          <button
            className="text-md h-12 w-full whitespace-nowrap rounded bg-red-light px-2 font-bold text-white shadow-primary disabled:cursor-not-allowed disabled:bg-coolGray-400 disabled:shadow-coolGray"
            type="submit"
            disabled={addingToCart || submitDisabled}
          >
            {addingToCart ? 'Adding...' : buttonLabel}
          </button>
          {submitDisabled ? (
            <div className="mt-2 flex justify-center space-x-1 grayscale filter">
              <StandardIcon icon="info" alt="" width={14} height={14} />
              <p className="text-sm text-coolGray-800">Choose another option</p>
            </div>
          ) : null}
        </div>
      </div>
      <div className="mt-2">
        <FormError error={error} analyticsAction={ANALYTICS_ACTIONS.cartAddErrorMessage} />
      </div>
    </>
  )
}

function PurchaseTypeRadioOption({
  autoshipDiscounts,
  className,
  autoshipFrequencyOptionsList,
  id,
  loadingSubscriptionPlans,
  onPurchaseTypeChange,
  onSelectedFrequencyValueChange,
  price,
  productHasAutoshipRecurringDiscount,
  selectedFrequency,
  selectedPurchaseType,
  title,
  binPickingNumber,
  discountedPriceDetails,
  selectedVariantInStock,
  showEstimatedDeliveryDate,
  showStockIndicator,
}: CartRadioInputProps) {
  const customerHasAutoshipSubscriptions = useCustomerHasAutoshipSubscriptions()
  const isSelectedAutoshipPurchaseType = id === 'atc-autoship'
  const isSelected = selectedPurchaseType === id
  const { earliestDeliveryDay, latestDeliveryDay } = calculateOrderDeliveryDate()

  const { adjustedPrice, message, unitPrice } = autoshipPricingAndMessage({
    autoshipDiscounts,
    binPickingNumber,
    isSelectedAutoshipPurchaseType,
    price,
    productHasAutoshipRecurringDiscount,
    showInitialDiscount: !customerHasAutoshipSubscriptions,
  })

  return (
    <div className={clsx('px-4 pb-4', className, isSelected ? 'space-y-2' : 'space-y-4')}>
      <div className="flex select-none items-start space-y-2">
        <input
          className="mt-4 cursor-pointer"
          type="radio"
          id={id}
          value={id}
          aria-labelledby={`${id}-title`}
          checked={isSelected}
          onChange={onPurchaseTypeChange}
        />
        <label
          className="ml-2 flex max-w-full cursor-pointer flex-col overflow-hidden text-pebble"
          htmlFor={id}
        >
          <p id={`${id}-title`} className="font-bold">
            {title}
          </p>
          <div className="space-y-2">
            <div className="flex flex-wrap items-center space-x-2 text-lg">
              <p className="text-xl font-bold text-red-dark" title={formatCurrency(adjustedPrice)}>
                {formatCurrencyWithSuperscriptCents(adjustedPrice)}
              </p>
              {unitPrice ? (
                <p className="text-sm text-black">({formatCurrency(unitPrice)}/ea)</p>
              ) : null}
              {discountedPriceDetails?.percentage ? (
                <p className="flex items-center justify-center rounded bg-[#993939] px-2.5 text-sm font-bold text-white">
                  SAVE {discountedPriceDetails?.percentage}%
                </p>
              ) : null}
            </div>
            {isSelected && showStockIndicator && (
              <p
                className={clsx(
                  'text-base font-bold',
                  selectedVariantInStock ? 'text-seaweed' : 'text-pepper'
                )}
              >
                {selectedVariantInStock ? 'In stock' : 'Out of stock'}
              </p>
            )}

            <div className="space-y-1">
              {message && (
                <p className="text-base font-normal leading-none tracking-tight">{message}</p>
              )}
              {isSelected && isSelectedAutoshipPurchaseType ? (
                <>
                  <ul className="list-disc pl-5 text-sm font-normal">
                    <li>Email reminder 3 days before it ships</li>
                    <li>Delay or cancel anytime</li>
                  </ul>
                  {!customerHasAutoshipSubscriptions && (
                    <p className="text-xs leading-none text-pebble-750">
                      *Max savings of ${AUTOSHIP.promotions.first.discount.dollarMax} on first
                      Autoship order
                    </p>
                  )}
                </>
              ) : null}
            </div>
            {isSelected &&
              isSelectedAutoshipPurchaseType &&
              onSelectedFrequencyValueChange &&
              autoshipFrequencyOptionsList && (
                <OptionDropdown
                  inputName="autoshipFrequencySelector"
                  isLoading={loadingSubscriptionPlans}
                  optionsList={autoshipFrequencyOptionsList}
                  onChange={onSelectedFrequencyValueChange}
                  purpleSelectionIndicator
                  renderButtonLabel={({ isLoading, selectedOption }) => (
                    <>
                      <p className="color-[#2B2B4A] text-[0.75em]">Delivery frequency:</p>
                      {!isLoading ? (
                        <p className="color-[#2B2B4A] truncate text-sm">
                          {selectedOption?.name || 'Select a frequency...'}
                          {selectedOption?.isSubscriptionPlan && (
                            <span className="ml-1 text-[0.85em] lowercase">
                              ({selectedOption?.sublabel})
                            </span>
                          )}
                        </p>
                      ) : (
                        <p className="h-5 animate-pulse bg-coolGray-200" />
                      )}
                    </>
                  )}
                  selectedOption={selectedFrequency}
                  title="Select a Frequency"
                />
              )}
            {showEstimatedDeliveryDate && (
              <p className="text-base leading-none">
                {isSelectedAutoshipPurchaseType ? 'First' : 'Expected'} delivery&nbsp;
                <span className="font-bold text-seaweed">
                  {earliestDeliveryDay}&nbsp;-&nbsp;
                  {latestDeliveryDay}
                </span>
              </p>
            )}
          </div>
        </label>
      </div>
    </div>
  )
}

export const AddToCartForm = forwardRef(
  (
    {
      autoshipFrequencyOptionsList,
      className,
      discountedPriceDetails,
      enableCtaButtons = true,
      hideAutoshipPricing,
      quantity,
      onSelectedFrequencyValueChange,
      onSubmit,
      selectedFrequency,
      selectedPurchaseType,
      children,
      binPickingNumber,
      ...rest
    }: AddToCartFormProps,
    formRef: FormFocusRef
  ) => {
    const isSelectedAutoshipPurchaseType = selectedPurchaseType === 'atc-autoship'
    const displayShippingDelayAlert = featureFlags.displayShippingDelayAlert

    return (
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault()
          onSubmit()
        }}
      >
        <section className={clsx('bg-white', className)}>
          {!hideAutoshipPricing && (
            <PurchaseTypeRadioOption
              className={clsx(
                'rounded-t border border-b-0',
                isSelectedAutoshipPurchaseType
                  ? 'border-orange bg-orange bg-opacity-5'
                  : 'border-gray-300'
              )}
              buttonLabel="Add to Autoship"
              title="Autoship & Save"
              id="atc-autoship"
              autoshipFrequencyOptionsList={autoshipFrequencyOptionsList}
              discountedPriceDetails={discountedPriceDetails}
              onSelectedFrequencyValueChange={onSelectedFrequencyValueChange}
              quantity={quantity}
              selectedFrequency={selectedFrequency}
              selectedPurchaseType={selectedPurchaseType}
              binPickingNumber={binPickingNumber}
              {...rest}
            />
          )}
          <PurchaseTypeRadioOption
            className={clsx(
              'rounded-b border',
              !isSelectedAutoshipPurchaseType
                ? 'border-orange bg-orange bg-opacity-5'
                : 'border-t border-gray-300 border-t-orange',
              hideAutoshipPricing && 'rounded-t'
            )}
            title="One-time purchase"
            id="atc-standard"
            quantity={quantity}
            selectedPurchaseType={selectedPurchaseType}
            binPickingNumber={binPickingNumber}
            {...rest}
          />
        </section>
        {displayShippingDelayAlert && (
          <div className="mt-4 flex space-x-1 rounded-md border-2 border-solid border-blueberry bg-sky-500 p-2 text-blueberry">
            <LegacyExclamationIcon className="mt-0.5 h-4 w-4" />
            <p className="flex-1 text-sm font-bold">
              Due to increased shipping demand during the holidays, shipping may take longer than
              usual.
            </p>
          </div>
        )}
        {enableCtaButtons && (
          <>
            <StickyCTAHeader formRef={formRef}>
              <PurchaseTypeCTAButtons
                buttonLabel={isSelectedAutoshipPurchaseType ? 'Add to Autoship' : 'Add to Cart'}
                quantity={quantity}
                {...rest}
              />
            </StickyCTAHeader>
            <PurchaseTypeCTAButtons
              buttonLabel={isSelectedAutoshipPurchaseType ? 'Add to Autoship' : 'Add to Cart'}
              className="mt-4"
              quantity={quantity}
              {...rest}
            />
          </>
        )}
        {children}
      </form>
    )
  }
)

export default AddToCartForm
