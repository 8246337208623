import AUTOSHIP from '../../../constants/AUTOSHIP'
import type { BigCommerceProductPrices } from '../../../serverOnly/api/bigcommerce/graphql/product/types'

export function calculatePricing(prices?: BigCommerceProductPrices) {
  if (!prices || !prices.price) return {}

  const { first, recurring } = AUTOSHIP.promotions
  const { price, retailPrice, salePrice } = prices

  let finalPrice = price.value
  let savingsTotal = 0

  if (retailPrice?.value) {
    savingsTotal += retailPrice?.value - price.value
  }

  if (salePrice?.value) {
    savingsTotal += price.value - salePrice?.value
    finalPrice = salePrice?.value
  }

  let autoshipFirstDiscount = (finalPrice * first.discount.percentage) / 100
  autoshipFirstDiscount =
    first.discount.dollarMax && autoshipFirstDiscount > first.discount.dollarMax
      ? first.discount.dollarMax
      : autoshipFirstDiscount

  return {
    price: price?.value,
    retailPrice: retailPrice?.value,
    salePrice: salePrice?.value,
    savings: {
      total: savingsTotal,
      percentage: Math.floor((savingsTotal / (retailPrice?.value || price.value)) * 100),
    },
    autoship: {
      firstDiscount: {
        total: finalPrice - autoshipFirstDiscount,
        percentage: first.discount.percentage,
      },
      reccuringDiscount: {
        total: finalPrice - (finalPrice * recurring.discount.percentage) / 100,
        percentage: recurring.discount.percentage,
      },
    },
  }
}

export type CalculatePricingResult = ReturnType<typeof calculatePricing>
