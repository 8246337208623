import useCartQuery from '../../../hooks/useCartQuery'
import useRedirectToCheckout from '../../../hooks/useRedirectToCheckout'
import ANALYTICS_ACTIONS from '../../../lib/constants/ANALYTICS_ACTIONS'
import { Button } from './Button'

export default function CheckoutButton({
  disabled = false,
  extraClassName = '',
  onClick,
  size = 'lg',
  stretched = true,
  label = 'Checkout',
}: {
  disabled?: boolean
  extraClassName?: string
  onClick?: () => void
  size?: 'lg' | 'sm'
  stretched?: boolean
  label?: string
}) {
  const { isLoading: isLoadingCart } = useCartQuery()
  const { handleCheckoutRedirect, redirecting } = useRedirectToCheckout()

  return (
    <Button
      variant="fill"
      stretched={stretched}
      className={extraClassName}
      data-cw-track={ANALYTICS_ACTIONS.checkoutBtnClick}
      size={size}
      onClick={() => {
        onClick?.()
        return handleCheckoutRedirect()
      }}
      disabled={redirecting || disabled || isLoadingCart}
    >
      {redirecting ? '...redirecting' : label}
    </Button>
  )
}
