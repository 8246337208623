import clsx from 'clsx'
import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'
import type { DropdownOption, OptionDropdownProps, RenderOptionLabel } from './OptionDropdown'

function DefaultRenderOptionLabel({ option }: RenderOptionLabel) {
  return (
    <>
      <p>{option.name}</p>
      {option.label ? <p className="font-normal text-coolGray-800">{option.label}</p> : null}
      {option.sublabel ? <p className="text-coolGray-800">{option.sublabel}</p> : null}
    </>
  )
}

export type OptionDropdownListItemProps = Pick<
  OptionDropdownProps,
  'isHighlighted' | 'purpleSelectionIndicator' | 'renderOptionLabel'
> & {
  dropdownContainerRef: RefObject<HTMLDivElement | null>
  isChecked: boolean
  option: DropdownOption
}

export function OptionDropdownListItem({
  dropdownContainerRef,
  isChecked,
  isHighlighted,
  option,
  purpleSelectionIndicator,
  renderOptionLabel = DefaultRenderOptionLabel,
}: OptionDropdownListItemProps) {
  const optionRef = useRef<HTMLLIElement | null>(null)
  const isDisabled = !option.available

  let optionClass = 'border-transparent'
  if (isChecked && purpleSelectionIndicator) {
    optionClass = 'font-semibold border-primary-900 bg-[#EFEAF4]'
  } else if (isChecked) {
    optionClass = 'font-semibold '.concat(
      isHighlighted ? 'border-red-light bg-[#FDF7F4]' : 'border-coolGray-400 bg-coolGray-50'
    )
  }

  // keep the active selected option within the viewport
  useEffect(() => {
    if (optionRef.current && dropdownContainerRef.current && isChecked) {
      dropdownContainerRef.current.scrollTo(
        0,
        optionRef.current.offsetTop - optionRef.current.clientHeight
      )
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    if (optionRef.current && isChecked) {
      optionRef.current.focus()
    }
  }, [isChecked])

  return (
    <li
      id={option.name}
      data-value={option.value}
      aria-selected={isChecked}
      aria-disabled={isDisabled}
      className={clsx(
        'border border-l-8 bg-white p-4 transition-colors',
        isDisabled ? 'text-coolGray-600 line-through' : 'cursor-pointer text-coolGray',
        optionClass,
        purpleSelectionIndicator
          ? 'hover:bg-[#EFEAF4] focus:hover:bg-[#EFEAF4]'
          : 'hover:bg-[#FDF7F4] focus:bg-[#FDF7F4]'
      )}
      ref={optionRef}
      role="option"
      tabIndex={-1}
    >
      {renderOptionLabel({ isChecked, option })}
    </li>
  )
}
