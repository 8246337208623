import clsx from 'clsx'
import Link from 'next/link'
import type { ReactNode } from 'react'
import routes from '../../../lib/routes'

export default function ManageExistingAutoshipLink({
  children,
  className,
  planId,
}: {
  children?: ReactNode
  className?: string
  planId?: string
}) {
  return (
    <Link
      href={planId ? routes.account.autoship(planId) : routes.account.autoships()}
      className={clsx('underline', className)}
    >
      {children || 'Manage Autoship'}
    </Link>
  )
}
