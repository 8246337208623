import type { ChangeEvent, FormEvent } from 'react'
import { useState } from 'react'
import useAddPlanItemMutation from '../../../hooks/pageType/account/autoship/useAddPlanItemMutation'
import { useMobileCheck } from '../../../hooks/useMobileCheck'
import { FormError } from '../../global/form/FormError'
import { ModalContent } from '../../global/modal/ModalContent'
import { ModalHeader } from '../../global/modal/ModalHeader'

export type AutoshipDeliveryType = 'next-autoship-delivery' | 'order-now'

export type AutoshipShipmentRadioOption = {
  id: AutoshipDeliveryType
  onAutoshipDeliveryChange: (event: ChangeEvent<HTMLInputElement>) => void
  selectedAutoshipDelivery: AutoshipDeliveryType
  subtitle?: string
  title: string
}

function AutoshipShipmentRadioOption({
  id,
  onAutoshipDeliveryChange,
  selectedAutoshipDelivery,
  subtitle,
  title,
}: AutoshipShipmentRadioOption) {
  return (
    <label
      className="flex cursor-pointer flex-row space-y-2 text-lg font-semibold text-coolGray"
      htmlFor={id}
    >
      <input
        className="mt-4 mr-4 cursor-pointer"
        type="radio"
        id={id}
        value={id}
        checked={id === selectedAutoshipDelivery}
        onChange={onAutoshipDeliveryChange}
      />
      <div className="flex flex-col">
        <p>{title}</p>
        {subtitle ? <p className="text-base font-normal">{subtitle}</p> : null}
      </div>
    </label>
  )
}

export type AddToExistingAutoshipFormModalProps = {
  handleAddProductVariantToCart: () => Promise<void>
  planId?: string
  productQuantity: number
  productSku?: string
  onClose: () => void
  upcomingASDeliveryDate?: string | number | Date
  showAddedToExistingASConfirmationModal: () => void
}

export function AddToExistingAutoshipFormModal({
  handleAddProductVariantToCart,
  planId,
  productQuantity,
  productSku,
  onClose,
  showAddedToExistingASConfirmationModal,
  upcomingASDeliveryDate = '',
}: AddToExistingAutoshipFormModalProps) {
  const { mobile } = useMobileCheck()
  const [formError, setFormError] = useState('')
  const [selectedAutoshipDelivery, setSelectedAutoshipDelivery] =
    useState<AutoshipDeliveryType>('next-autoship-delivery')
  const handleAutoshipDeliveryChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSelectedAutoshipDelivery(event.target.value as AutoshipDeliveryType)
  }
  const [submittingForm, setSubmittingForm] = useState(false)

  const { mutate: addPlanItem } = useAddPlanItemMutation({
    onSuccess: async () => {
      onClose()
      if (selectedAutoshipDelivery === 'next-autoship-delivery') {
        showAddedToExistingASConfirmationModal()
      } else {
        await handleAddProductVariantToCart()
      }
    },
    onError: () => {
      setSubmittingForm(false)
      setFormError('Error adding product item to existing Autoship subscription plan')
    },
  })

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setSubmittingForm(true)
    await addPlanItem({ planId, productQuantity, productSku })
  }

  return (
    <>
      <ModalHeader
        className="!bg-white"
        onClose={onClose}
        alignTitleLeft={mobile}
        title="Select your first delivery date"
      />
      <ModalContent className="overflow-x-hidden !bg-white lg:max-h-[85vh]">
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col space-y-4">
            <AutoshipShipmentRadioOption
              id="next-autoship-delivery"
              onAutoshipDeliveryChange={handleAutoshipDeliveryChange}
              selectedAutoshipDelivery={selectedAutoshipDelivery}
              title={`Get it with your next Autoship & Save delivery on ${upcomingASDeliveryDate}.`}
            />
            <hr className="border-coolGray-100" />
            <AutoshipShipmentRadioOption
              id="order-now"
              onAutoshipDeliveryChange={handleAutoshipDeliveryChange}
              selectedAutoshipDelivery={selectedAutoshipDelivery}
              title="Order now with 1-2 day shipping."
              subtitle={`Your second delivery will arrive on ${upcomingASDeliveryDate}.`}
            />
            {formError && <FormError error={formError} />}
          </div>
          <div className="my-6">
            <button
              type="submit"
              disabled={submittingForm}
              className="h-14 w-full flex-none whitespace-nowrap rounded bg-red-light px-2 text-xl font-bold text-white shadow-primary disabled:cursor-not-allowed disabled:bg-coolGray-400 disabled:shadow-coolGray"
            >
              {submittingForm ? 'Adding to existing Autoship...' : 'Confirm'}
            </button>
          </div>
        </form>
      </ModalContent>
    </>
  )
}
