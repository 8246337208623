import clsx from 'clsx'
import Image from 'next/legacy/image'
import React from 'react'

export enum RecommendationImageHeight {
  Small = 'h-[7rem] md:h-[7rem]',
  Medium = 'h-[10rem] md:h-[12.5rem]',
}

export function RecommendationsWidgetImage({
  src,
  priority = false,
  alt,
  height = RecommendationImageHeight.Medium,
}: {
  src: string
  priority?: boolean
  alt: string
  height?: RecommendationImageHeight
}) {
  return (
    <div className={clsx('max-w-60 relative mx-1 mt-2 w-auto sm:mx-2 sm:mt-0  md:mx-6', height)}>
      <Image
        src={src}
        layout="fill"
        objectFit="contain"
        priority={priority}
        alt={alt}
        quality={50}
      />
    </div>
  )
}
