import type { KeyboardEvent, MouseEvent, ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useWindowSize from '../../../../hooks/useWindowSize'
import { flattenDropdownOptionsList } from '../../../../lib/utils/findOrFlattenDropdownOptions'
import Modal from '../../modal/Modal'
import { ModalContent } from '../../modal/ModalContent'
import { ModalFooter } from '../../modal/ModalFooter'
import { ModalHeader } from '../../modal/ModalHeader'
import { OptionDropdownField } from './OptionDropdownField'
import { OptionDropdownListItem } from './OptionDropdownListItem'

export type OptionsList = Array<{
  groupName?: string
  options: Array<DropdownOption>
}>

export type DropdownOption = {
  available: boolean
  isSubscriptionPlan?: boolean
  label?: string
  name: string
  sublabel?: ReactNode
  subscriptionPlanShipOnDate?: string
  unitPrice?: string | number
  value: string
}

export type RenderButtonLabel = {
  isLoading?: boolean
  selectedOption?: DropdownOption
  totalOptions: number
}

export type RenderOptionLabel = {
  isChecked: boolean
  option: DropdownOption
}

export type OptionDropdownProps = {
  inputName: string
  isHighlighted?: boolean
  isLoading?: boolean
  onChange: (selectedOption: DropdownOption) => void
  optionsList?: OptionsList
  purpleSelectionIndicator?: boolean
  required?: boolean
  renderButtonLabel?: (props: RenderButtonLabel) => ReactNode
  renderOptionLabel?: (props: RenderOptionLabel) => ReactNode
  title?: string
  selectedOption?: DropdownOption
}

/**
 * A reusable dropdown selector that will open a modal and display a (grouped) list of options.
 *
 * @param inputName - a shared input name for each option input
 * @param isLoading - an optional boolean to display a skeleton until some state is done loading
 * @param isHighlighted - will highlight the main button with orange
 * @param onChange - a parent callback function that will be invoked after a selection was made and update the `selectedOption` property
 * @param optionsList - a structured array of `[{ groupName: '', options: [{...}] }]` options -- the `groupName` is optional, the `options` are not
 * @param purpleSelectionIndicator - will switch the selection/hover color from orange to purple
 * @param renderButtonLabel - an optional functional prop that will be used to display the default/selected option within a button; it receives `selectedOption` and `totalOptions` as props
 * @param renderOptionLabel - an optional functional prop that will be used to display the option label; it receives `isChecked` and the entire `option` as props
 * @param required - an optional boolean to determine if a selected option is required
 * @param selectedOption - this value will be used to determine which option is selected
 * @param title - an optional modal title to display when the modal is opened
 */
export function OptionDropdown({
  inputName,
  isHighlighted = false,
  isLoading = false,
  onChange,
  optionsList = [],
  purpleSelectionIndicator = false,
  renderButtonLabel,
  renderOptionLabel,
  required = false,
  selectedOption,
  title = 'Select an Option',
}: OptionDropdownProps) {
  const { height: windowHeight } = useWindowSize()
  const modalHeaderRef = useRef<HTMLDivElement | null>(null)
  const modalFooterRef = useRef<HTMLDivElement | null>(null)
  const dropdownContainerRef = useRef<HTMLDivElement | null>(null)
  const [displayOptions, setDisplayOptions] = useState(false)
  const [fadeWidth, setFadeWidth] = useState(0)
  const [minMaxModalContentHeight, setMinMaxModalContentHeight] = useState(0)
  const [activeOptionIndex, setActiveOptionIndex] = useState(0)

  const flatOptionsList = useMemo(() => flattenDropdownOptionsList(optionsList), [optionsList])

  const onButtonClick = useCallback(() => {
    setDisplayOptions(true)
  }, [])

  const handleOptionFocusChange = (event: KeyboardEvent<HTMLDivElement>) => {
    if (!displayOptions) return

    const { key } = event

    // https://w3c.github.io/aria-practices/#listbox_kbd_interaction
    // ⬆️ moves focus and selection to previous option
    // ⬇️ moves focus and selection to next option
    const prevOption = key === 'ArrowUp'
    const nextOption = key === 'ArrowDown'

    if (prevOption) {
      event.preventDefault()
      const prevOption = flatOptionsList[activeOptionIndex - 1]
      if (prevOption) onChange(prevOption)
    } else if (nextOption) {
      event.preventDefault()
      const nextOption = flatOptionsList[activeOptionIndex + 1]
      if (nextOption) onChange(nextOption)
    }
  }

  const handleOptionSelect = (event: MouseEvent<HTMLDivElement>) => {
    const optionElement: HTMLElement | null = (event.target as HTMLElement).closest(
      "[role='option']"
    )
    if (!optionElement) return
    const optionValue = optionElement.dataset.value
    const selectedOption = flatOptionsList.find((option) => option.value === optionValue)

    if (selectedOption) {
      onChange(selectedOption)
      setTimeout(() => setDisplayOptions(false), 200)
    }
  }

  // calculate the fade width for content scroll and the min/max height for mobile devices
  useEffect(() => {
    if (dropdownContainerRef.current && displayOptions) {
      setFadeWidth(dropdownContainerRef.current.clientWidth)
    }

    if (modalHeaderRef.current && modalFooterRef.current && displayOptions && windowHeight) {
      const viewportHeight = windowHeight * 0.01 * 100
      const modalTopAndBottomHeights =
        modalHeaderRef.current.clientHeight + modalFooterRef.current.clientHeight + 14
      setMinMaxModalContentHeight(viewportHeight - modalTopAndBottomHeights)
    }
  }, [displayOptions, windowHeight])

  // when a new option is selected, keeps the internal active option index up-to-date
  useEffect(() => {
    if (selectedOption) {
      setActiveOptionIndex(
        flatOptionsList.findIndex((option) => option.value === selectedOption.value)
      )
    }
  }, [displayOptions, flatOptionsList, selectedOption])

  return (
    <>
      <OptionDropdownField
        disabled={optionsList.length === 0 || isLoading}
        isHighlighted={isHighlighted}
        isLoading={isLoading}
        inputName={inputName}
        handleClick={onButtonClick}
        selectedOption={selectedOption}
        totalOptions={flatOptionsList.length}
        renderButtonLabel={renderButtonLabel}
        required={required}
      />
      {displayOptions && (
        <Modal size="small" show={displayOptions} onHide={() => setDisplayOptions(false)}>
          <ModalHeader
            ref={modalHeaderRef}
            title={title}
            onClose={() => setDisplayOptions(false)}
          />
          <ModalContent className="relative !space-y-0 !overflow-x-hidden !bg-white !p-1.5">
            <div
              className="absolute bottom-0 h-12 bg-gradient-to-t from-white"
              style={{ width: fadeWidth }}
            />
            <div
              className="w-full overflow-y-auto pt-2.5 pb-10 sm:!max-h-[30rem] sm:!min-h-0"
              style={{
                minHeight: minMaxModalContentHeight,
                maxHeight: minMaxModalContentHeight,
              }}
              ref={dropdownContainerRef}
              role="listbox"
              aria-labelledby="modal-title"
              aria-activedescendant={selectedOption?.name}
              tabIndex={0}
              onKeyDown={handleOptionFocusChange}
              onClick={handleOptionSelect}
            >
              {optionsList.map(({ groupName, options }, index) => (
                <ul className="list-none" role="group" aria-labelledby={groupName} key={index}>
                  {groupName && (
                    <li
                      id={groupName}
                      role="presentation"
                      className="color-[#55556E] bg-white px-4 pt-2 pb-1 text-sm font-semibold capitalize"
                    >
                      {groupName}
                    </li>
                  )}
                  {options.map((option) => (
                    <OptionDropdownListItem
                      key={option.value}
                      dropdownContainerRef={dropdownContainerRef}
                      isChecked={selectedOption?.value === option.value}
                      isHighlighted={isHighlighted}
                      option={option}
                      purpleSelectionIndicator={purpleSelectionIndicator}
                      renderOptionLabel={renderOptionLabel}
                    />
                  ))}
                </ul>
              ))}
            </div>
          </ModalContent>
          <ModalFooter ref={modalFooterRef} />
        </Modal>
      )}
    </>
  )
}
