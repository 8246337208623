import type { LineItem } from '@corewell/cart'
import type { DataLayerItem } from './DataLayerTypes'

export function getGTMItem(
  { productName, productId, couponAmount, price, quantity, variantId }: LineItem,
  overrides: Partial<DataLayerItem> = {}
) {
  return {
    item_id: productId.toString(),
    item_name: productName,
    discount: couponAmount,
    price,
    // item_brand: brand, TODO: Why no brand?
    item_list_id: 'cart',
    item_list_name: 'Cart',
    quantity: quantity,
    item_variant: variantId.toString(),
    ...overrides,
  }
}

export function getEcommerceValue(items: DataLayerItem[]) {
  return items.reduce((memo, item) => {
    const totalDiscount = item.discount && item.quantity ? item.discount * item.quantity : 0
    const totalPrice = item.price ? item.price * (item.quantity ?? 1) : 0
    return memo + totalPrice - totalDiscount
  }, 0)
}

const ITEM_STORAGE_PREFIX = 'ADDED_TO_CART_ITEM_'

const getItemKey = (itemId: string) => `${ITEM_STORAGE_PREFIX}${itemId}`

export function saveItemToLocalStorage(item: any) {
  const itemkey = getItemKey(item.item_id)
  localStorage.setItem(itemkey, JSON.stringify(item));
}

export function getItemFieldsFromLocalStorage(itemId: string): Record<string, string> {
  const itemkey = getItemKey(itemId)
  const item = JSON.parse(localStorage.getItem(itemkey) ?? '{}')

  const fields = { 
    item_category: item.item_category,
    item_category2: item.item_category2,
    item_category3: item.item_category3,
    item_category4: item.item_category4,
    item_category5: item.item_category5,
    item_brand: item.item_brand ?? ''
  }

  const keys = Object.keys(fields)

  // Avoid any json/undefined issues en route to GA4
  keys.forEach((k) => {
    if (!fields[k]) delete fields[k]
  })

  return fields
}

export function removeItemFromLocalStorage(itemId: string) {
  const itemkey = getItemKey(itemId)
  localStorage.removeItem(itemkey)
}
