import { useCallback } from 'react'
import type { InferProcedureOutput, Procedures } from '../../../../server/routers/_app'
import type { DataLayerItem } from './DataLayerTypes'
import useDataLayerPush from './useDataLayerPush'

export default function useGTMAddToCartEvent() {
  const push = useDataLayerPush()

  return useCallback(
    ({
      value,
      items,
      customer,
    }: {
      value: number
      items: DataLayerItem[]
      customer: InferProcedureOutput<Procedures['customer']['getCustomer']>
    }) => {
      // Clear the previous ecommerce object
      push({
        ecommerceData: null,
      })
      push({
        mainData: {},
        ecommerceData: {
          currency: 'USD',
          items,
          value,
        },
        event: {
          name: 'add_to_cart',
        },
        customer,
      })
    },
    [push]
  )
}
