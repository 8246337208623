export default function RecommendationsWidgetChevronIcon() {
  return (
    <svg className="mx-auto block w-3 text-coolGray" viewBox="0 0 18 30">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-245.000000, -168.000000)" fill="currentColor" fillRule="nonzero">
          <g transform="translate(245.000000, 168.000000)">
            <polygon points="0 1.7689355 1.88747668 0 17.5070801 14.589222 1.88747668 29.178444 0 27.4186268 13.7229891 14.589222" />
          </g>
        </g>
      </g>
    </svg>
  )
}
