import clsx from 'clsx'
import { useMobileCheck } from '../../../../hooks/useMobileCheck'
import range from '../../../../lib/utils/range'
import type { Recommendation, Recommendations } from '../../nosto/types'
import { RecommendationsWidgetCard } from '../RecommendationsWidget/RecommendationsWidgetCard'

export type RecommendationsGridProps = {
  recommendations?: Recommendations | null
  deal?: boolean
  addToCart?: boolean
  productClickTracker?: (rec: Recommendation) => void
}

function RecommendationsGrid({
  recommendations,
  deal,
  addToCart = false,
  productClickTracker = () => null,
}: RecommendationsGridProps) {
  const { mobile: isMobile } = useMobileCheck()
  const products = recommendations?.products
  const productCount = products?.length || 0

  // limit showing products to 12 for desktop and 8 for mobile
  const recommendationLimit = !isMobile ? 12 : 8
  const recommendationProducts = products?.slice(0, recommendationLimit)

  if (!recommendationProducts || !productCount) {
    return (
      <div className="space-y-4">
        <div
          className={clsx(
            'animate-pulse rounded-[7px] border bg-gray-300',
            addToCart ? 'h-7' : 'h-9'
          )}
        />
        <div className="grid grid-cols-12 gap-2 px-2 sm:px-0">
          {range(0, 12).map((idx) => (
            <div
              key={idx}
              className="col-span-6 min-h-[360px] animate-pulse rounded-[7px] border bg-gray-300 px-1 py-0.5 sm:min-h-[456px] sm:px-3.5 sm:py-3 md:col-span-4 lg:col-span-2"
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-4">
      <h2
        className={clsx(
          'font-semibold text-coolGray',
          addToCart ? 'text-xl' : 'text-2xl sm:text-4xl lg:text-3xl'
        )}
      >
        {recommendations?.title}
      </h2>
      <div className="grid grid-cols-12 gap-2">
        {recommendationProducts.map((recommendation) => (
          <RecommendationsWidgetCard
            key={recommendation.product_id}
            addToCart={addToCart}
            deal={deal}
            overrideClassName="col-span-6 relative px-1 py-0.5 border rounded-[7px] bg-white border-eggshell sm:px-3.5 sm:py-3 md:col-span-4 lg:col-span-2"
            productHref={new URL(recommendation.url).pathname}
            productClickTracker={productClickTracker}
            recommendation={recommendation}
            useCardDimensions={false}
          />
        ))}
      </div>
    </div>
  )
}

export default RecommendationsGrid
