import type { ReactNode } from 'react'
import { formatCurrency } from './currency'

function SuperscriptText({ children }: { children: ReactNode }) {
  return (
    <span style={{ verticalAlign: 'super' }} className="text-xs font-normal">
      {children}
    </span>
  )
}

export function currencyWithSubscriptCents(price: string) {
  const [dollars, cents] = price.slice(1).split('.')

  return (
    <>
      <SuperscriptText>&#36;</SuperscriptText>
      <span style={{ marginRight: '0.05rem' }}>{dollars}</span>
      <SuperscriptText>{cents}</SuperscriptText>
    </>
  )
}

export function formatCurrencyWithSuperscriptCents(n = 0) {
  const price = formatCurrency(n)
  return currencyWithSubscriptCents(price)
}
