import { trpc } from '../lib/utils/trpc'
import type { InferProcedureInput, ReactQueryProcedureOptions } from '../server/routers/_app'
import { type Procedures } from '../server/routers/_app'

export function useAddToCartMutation({
  onSuccess,
  ...rest
}: ReactQueryProcedureOptions['cart']['addLineItem']['options'] = {}) {
  const utils = trpc.useUtils()

  return trpc.cart.addLineItem.useMutation({
    onSuccess: async (data, variables, context) => {
      onSuccess?.(data, variables, context)
      await utils.cart.invalidate()
    },
    ...rest,
  })
}

export type AddToCartMutationInput = InferProcedureInput<Procedures['cart']['addLineItem']>
