import type {
  DropdownOption,
  OptionsList,
} from '../../components/global/form/dropdown/OptionDropdown'

export type FlattenedDropdownOptions = Array<DropdownOption>

/**
 * A utility function to flatten a structured options list into a single array of options
 *
 * @param optionsList - a structured array of `[{ groupName: '', options: [{...}] }]`
 * @returns a flattened array of dropdown options
 */
export function flattenDropdownOptionsList(optionsList: OptionsList) {
  return optionsList.reduce(
    (acc: FlattenedDropdownOptions, { options }) => [...acc, ...options],
    []
  )
}

/**
 * A utility function to find a dropdown option via a string. It searches for a partial
 * match by checking if an option's `value`, `label` or `name` includes the `selectedOption`.
 *
 * @param optionsList - a structured array of `[{ groupName: '', options: [{...}] }]` options -- the `groupName` is optional, the `options` are not
 * @param selectedOption - the selected option to find
 * @returns a single dropdown option or undefined
 */
export function findDropdownOption(optionsList: OptionsList, selectedOption: string) {
  return flattenDropdownOptionsList(optionsList).find(
    (option) =>
      option.value.includes(selectedOption) ||
      option.name.includes(selectedOption) ||
      option.label?.includes(selectedOption)
  )
}
