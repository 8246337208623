import type { ReactNode } from 'react'
import SHIPPING_METHOD from '../../lib/constants/SHIPPING_METHOD'
import { formatCurrency } from '../../lib/utils/currency'
import { appendRegularPluralSuffix } from '../../lib/utils/grammar'
import StandardIcon from './icons/StandardIcon'

const {
  label: shippingLabel,
  threshold: shippingThreshold,
  dayEstimate: shippingEstimate,
} = SHIPPING_METHOD.promotional

export type ShippingProgressProps = {
  baseAmount?: number
  cartCount?: number
  autoshipDiscount?: number
  renderShippingProgressCompleteLabel?: () => ReactNode
}

export default function ShippingProgress({
  baseAmount,
  cartCount,
  autoshipDiscount,
  renderShippingProgressCompleteLabel = () => (
    <span className="font-semibold">Your order ships {shippingLabel}!</span>
  ),
}: ShippingProgressProps) {
  const shippingProgress = baseAmount ? (baseAmount / shippingThreshold) * 100 : 0

  return (
    <div className="text-base md:text-lg">
      <div className="mb-2 text-center">
        {baseAmount ? (
          shippingProgress < 100 ? (
            <p>
              <span className="font-bold text-green-dark">
                {formatCurrency(shippingThreshold - baseAmount)} away
              </span>
              <span>&nbsp;from&nbsp;</span>
              <span className="font-bold">
                {`${shippingLabel} ${shippingEstimate.start}-${shippingEstimate.end} Day`}
              </span>
              <span>&nbsp;Shipping</span>
            </p>
          ) : (
            renderShippingProgressCompleteLabel()
          )
        ) : (
          <p>Loading...</p>
        )}
      </div>

      <div className="mb-4 h-3 rounded-full bg-[#c4c4c4]">
        <div
          role="progressbar"
          className="h-full w-0 rounded-full bg-green-dark"
          style={{ width: shippingProgress > 100 ? '100%' : `${shippingProgress}%` }}
          aria-label={`${shippingProgress.toFixed(2)}%`}
          aria-valuenow={shippingProgress}
          aria-valuemin={0}
          aria-valuemax={100}
        />
      </div>
      <div>
        <dl className="flex justify-between">
          <dt>
            <span className="font-bold">Subtotal&nbsp;</span>
            {cartCount ? `(${cartCount} ${appendRegularPluralSuffix('item', cartCount)}):` : null}
          </dt>
          <dd>
            {baseAmount ? (
              <span className="font-bold text-red-dark">
                {formatCurrency(autoshipDiscount ? baseAmount - autoshipDiscount : baseAmount)}
              </span>
            ) : null}
          </dd>
        </dl>
        {autoshipDiscount ? (
          <div className="flex justify-between pb-2">
            <p className="flex items-center text-base italic">
              <StandardIcon icon="autoship" alt="autoship icon" width={16} height={16} />
              <span className="pl-1">
                Includes Autoship discount of{' '}
                <span className="font-semibold text-pepper">
                  {formatCurrency(autoshipDiscount)}
                </span>
              </span>
            </p>
          </div>
        ) : null}
      </div>
    </div>
  )
}
