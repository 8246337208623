import clsx from 'clsx'
import debounce from 'lodash.debounce'
import { forwardRef, useEffect, useRef, useState } from 'react'
import { useBrowserLayoutEffect } from '../../../../hooks/useBrowserLayoutEffect'
import useWindowSize from '../../../../hooks/useWindowSize'
import breakpoints from '../../../../lib/breakpoints'
import { convertRemToPixels } from '../../../../lib/utils/convertRemToPixels'
import range from '../../../../lib/utils/range'
import RecommendationsWidgetChevronIcon from '../../icons/RecommendationsWidgetChevronIcon'
import type { Recommendation, Recommendations } from '../../nosto/types'
import Scroller, { SLIDE_CLASS_NAME } from '../../scroller/Scroller'
import { useRecommendationsWidgetCardDimensions } from './config'
import { RecommendationsWidgetCard } from './RecommendationsWidgetCard'

export type RecommendationsWidgetProps = {
  recommendations: Recommendations
  deal?: boolean
  maxNumCards?: number
  scrollContainerClassName?: string
  addToCart?: boolean
  fullWidth?: boolean
  cardWidths?: {
    default: number
    sm: number
    md: number
  } | null
  cardMargins?: {
    default: number
    sm: number
    md: number
  } | null
  productClickTracker?: (rec: Recommendation) => void
}

function PaginationButton({ className, onClick }: { className?: string; onClick?: () => void }) {
  return (
    <button
      className={clsx(
        'focus:outline-none inline-block h-8 w-8 rounded-full hover:bg-coolGray-100 focus:ring-2 focus:ring-secondary lg:h-10 lg:w-10 lg:border lg:border-coolGray',
        className
      )}
      onClick={onClick}
    >
      <RecommendationsWidgetChevronIcon />
    </button>
  )
}

const RecommendationsWidget = forwardRef<HTMLDivElement, RecommendationsWidgetProps>(
  (
    {
      recommendations,
      deal,
      maxNumCards = 4,
      scrollContainerClassName = 'rounded-lg',
      addToCart = false,
      fullWidth = false,
      cardWidths = null,
      cardMargins = null,
      productClickTracker = () => null,
    },
    forwardedRef
  ) => {
    const scrollRef = useRef<HTMLDivElement>(null)
    const containerRef = useRef<HTMLDivElement>(null)
    const productCount = recommendations?.products?.length || 0 // Product length or set to 0 if no products
    const productLengthBase = Math.max(productCount, 1) //Make sure that product Carousels start at 1 if recommendations are empty

    const [widgetId] = useState(
      `${Date.now().toString().slice(-6)}-${Math.floor(Math.random() * 100)}`
    )
    const [page, setPage] = useState(0)

    const { width: defaultCardWidth, margin: defaultCardMargin } =
      useRecommendationsWidgetCardDimensions(cardWidths, cardMargins)

    function getNumCardsThatFit() {
      if (!scrollRef.current || !containerRef.current) {
        return 0
      }
      const width = containerRef.current.clientWidth

      const cardWidth = convertRemToPixels(defaultCardWidth + defaultCardMargin * 2)
      const numCardsThatFit = Math.min(Math.floor(width / cardWidth), maxNumCards)

      return numCardsThatFit || 1
    }

    const [maxVisibleCards, setMaxVisibleCards] = useState(getNumCardsThatFit())

    //If the number of visible cards is 0 (because the references haven't been initialized yet), set the number of pages to 1
    const numPages = maxVisibleCards ? Math.ceil(productLengthBase / maxVisibleCards) : 1

    useBrowserLayoutEffect(() => {
      const nextNumCardsThatFit = Math.min(getNumCardsThatFit(), maxNumCards)
      setMaxVisibleCards(nextNumCardsThatFit)
    }, [maxNumCards, recommendations])

    // On window resize, recalculate how many cards
    useBrowserLayoutEffect(() => {
      const handleResize = debounce(
        () => {
          const nextNumCardsThatFit = Math.min(getNumCardsThatFit(), maxNumCards)
          setMaxVisibleCards(nextNumCardsThatFit)
        },
        350,
        { trailing: true }
      )

      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
    }, [maxNumCards])

    // Ensure page is never greater than num. pages e.g. after a resize event
    useEffect(() => {
      setPage((p) => {
        if (p > numPages - 1) {
          return numPages - 1
        }
        return p
      })
    }, [numPages])

    const { width: windowWidth } = useWindowSize()

    const desktopWidth = `${
      Math.min(productLengthBase, maxVisibleCards) * (defaultCardWidth + defaultCardMargin * 2)
    }rem`

    const isDesktop = windowWidth && windowWidth >= breakpoints.lg
    const isFullWidth = fullWidth || !isDesktop

    if (!recommendations || !productCount) {
      return (
        <div className="space-y-4">
          <div className="h-12 w-full bg-gray-100 px-2"></div>
          <div className="h-60 w-full bg-gray-100 px-2 md:h-96"></div>
        </div>
      )
    }

    return (
      <div ref={forwardedRef}>
        <div
          className="mx-auto flex flex-row px-2"
          style={{ width: isFullWidth ? '100%' : desktopWidth }}
        >
          <div className="flex flex-1 flex-col justify-center">
            <h2
              className={clsx(
                addToCart ? 'text-xl' : 'text-2xl sm:text-4xl lg:text-3xl',
                ' font-semibold text-coolGray'
              )}
            >
              {recommendations.title}
            </h2>
          </div>
          <div className="space-x-2">
            <PaginationButton
              className="rotate-180 transform"
              onClick={() => {
                setPage((p) => {
                  if (p === 0) {
                    return numPages - 1
                  }
                  return Math.max(0, p - 1)
                })
              }}
            />
            <PaginationButton
              onClick={() => {
                setPage((p) => {
                  if (p === numPages - 1) {
                    return 0
                  }
                  return Math.min(numPages - 1, p + 1)
                })
              }}
            />
          </div>
        </div>
        <div
          ref={containerRef}
          className={clsx(
            'flex flex-col items-center py-2 text-[#565777]',
            isFullWidth ? '' : 'lg:px-4'
          )}
        >
          <div className={clsx('flex flex-row overflow-visible', isFullWidth ? 'w-full' : '')}>
            <Scroller
              ref={scrollRef}
              className={scrollContainerClassName}
              style={{
                width: isFullWidth ? '100%' : desktopWidth,
              }}
              showScrollbar={true}
              page={page}
              onPageChange={(n) => {
                setPage(n)
              }}
            >
              <div
                className={clsx('flex flex-row', {
                  'justify-center': recommendations.products.length < maxVisibleCards,
                })}
              >
                {range(0, numPages).map((page) => {
                  const start = page * maxVisibleCards
                  const end = start + maxVisibleCards
                  const rs = recommendations.products.slice(start, end)
                  return (
                    <li
                      key={`${widgetId}-pg${page}`}
                      className={`lg:scroll-snap-left flex flex-row ${SLIDE_CLASS_NAME}`}
                    >
                      {rs.map((recommendation) => (
                        <RecommendationsWidgetCard
                          key={`${widgetId}-pg${page}-rec${recommendation.product_id}`}
                          addToCart={addToCart}
                          recommendation={recommendation}
                          deal={deal}
                          productHref={new URL(recommendation.url).pathname}
                          cardWidths={cardWidths}
                          cardMargins={cardMargins}
                          productClickTracker={productClickTracker}
                        />
                      ))}
                    </li>
                  )
                })}
              </div>
            </Scroller>
          </div>
        </div>
      </div>
    )
  }
)

export default RecommendationsWidget
