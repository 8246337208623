import SHIPPING_CUTOFF from '../../../constants/SHIPPING_CUTOFF_TIME'
import { formatDate } from '../../date'

/**
 * A utility function to determine the expected delivery date of an order
 * placed at the current local time.
 *
 * @param inputDate - optional date to use to calculate the estimated delivery
 */
export default function calculateOrderDeliveryDate(inputDate: Date = new Date(), format?: string) {
  const [cutOffHour, cutOffMinute, cutOffSeconds] = SHIPPING_CUTOFF
  const earliestDeliveryDay = new Date(inputDate)
  const latestDeliveryDay = new Date(inputDate)

  const cutOffShippingTime = new Date(inputDate)
  cutOffShippingTime.setHours(cutOffHour)
  cutOffShippingTime.setMinutes(cutOffMinute)
  cutOffShippingTime.setSeconds(cutOffSeconds)

  const duringShippingHours = inputDate < cutOffShippingTime

  const [offsetEarliest, offsetLatest] = {
    // Sunday - During/After: Tues or Wed
    0: [2, 3],
    // Monday - During: Tues or Wed | After: Wed or Thurs
    1: duringShippingHours ? [1, 2] : [2, 3],
    // Tuesday - During: Wed or Thurs | After: Thurs or Fri
    2: duringShippingHours ? [1, 2] : [2, 3],
    // Wednesday - During: Thurs or Fri | After: Fri or Mon
    3: duringShippingHours ? [1, 2] : [2, 5],
    // Thursday - During: Fri or Mon | After: Mon or Tues
    4: duringShippingHours ? [1, 4] : [4, 5],
    // Friday - During: Mon or Tues | After: Tues or Wed
    5: duringShippingHours ? [3, 4] : [4, 5],
    // Saturday - During/After: Tues or Wed
    6: [3, 4],
  }[inputDate.getDay()] as [number, number]

  earliestDeliveryDay.setDate(earliestDeliveryDay.getDate() + offsetEarliest)
  latestDeliveryDay.setDate(latestDeliveryDay.getDate() + offsetLatest)

  return {
    earliestDeliveryDay: formatDate(earliestDeliveryDay, format || 'DD, MM dddd'),
    latestDeliveryDay: formatDate(latestDeliveryDay, format || 'DD, MM dddd'),
  }
}
