import clsx from 'clsx'
import type { ReactNode } from 'react'
import { forwardRef } from 'react'

export type ModalFooterProps = {
  className?: string
  children?: ReactNode
}

export const ModalFooter = forwardRef<HTMLDivElement | null, ModalFooterProps>(
  ({ className = '', children }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx('flex flex-initial border-t border-coolGray-100 bg-gray-50 p-4', className)}
      >
        {children}
      </div>
    )
  }
)
