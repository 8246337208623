import type { SubscriptionFrequency } from '../../../hooks/pageType/account/autoship/useSubscription'
import type { AutoshipFrequencyOption } from '../../constants/types'
import { getRegularPluralSuffix } from '../grammar'

/**
 * A utility function to tranform an Autoship frequency option into a human readable string.
 *
 * @param option - a single Autoship frequency option
 * @param popularOption - an optional boolean to append "(most popular)" to the string
 * @returns a single string represented as: `Every n day(s)/week(s)/month(s)` with an optional `(most popular)` title
 */
export function formatAutoshipFrequencyOption(
  option?: AutoshipFrequencyOption | SubscriptionFrequency,
  popularOption?: boolean
) {
  return option?.value && option?.type
    ? `Every ${option.value} ${option.type}${getRegularPluralSuffix(option.value)}${
        popularOption ? ' (most popular)' : ''
      }`
    : ''
}
