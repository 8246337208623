import clsx from 'clsx'
import Image from 'next/legacy/image'
import { useMobileCheck } from '../../../hooks/useMobileCheck'
import { formatCurrency } from '../../../lib/utils/currency'
import StandardIcon from '../../global/icons/StandardIcon'
import { ModalContent } from '../../global/modal/ModalContent'
import { ModalHeader } from '../../global/modal/ModalHeader'
import ManageExistingAutoshipLink from './ManageExistingAutoshipLink'

export type AddedToExistingAutoshipConfirmationModalProps = {
  discountedPriceDetails?: { percentage?: number; total?: number }
  existingPlanFrequency: string
  onClose: () => void
  planId?: string
  planName?: string
  productHasAutoshipRecurringDiscount: boolean
  productImageUrl: string
  productPrice?: number
  productName: string
  productQuantity: string
  variantLabel?: string | null
  upcomingASDeliveryDate?: string | number
}

export type ExistingAutoshipPlanDetailsProps = Pick<
  AddedToExistingAutoshipConfirmationModalProps,
  'planId' | 'planName' | 'upcomingASDeliveryDate'
> & { className?: string }

function ExistingAutoshipPlanDetails({
  className,
  planId,
  planName,
  upcomingASDeliveryDate,
}: ExistingAutoshipPlanDetailsProps) {
  return (
    <div
      className={clsx(
        'col-span-12 bg-white md:col-span-6 md:border-l md:px-4 md:text-base md:border-l-coolGray-200',
        className
      )}
    >
      <div className="flex h-full space-y-3 text-base sm:static sm:mx-0 sm:w-auto md:justify-center md:p-4 md:text-lg">
        <div className="flex flex-col md:justify-center">
          <p>
            <strong className="text-coolGray">&#8220;{planName}&#8221;</strong>&nbsp;
            <ManageExistingAutoshipLink className="text-sm text-primary" planId={planId} />
          </p>
          <p className="text-base">
            Your next Autoship delivery is on <strong>{upcomingASDeliveryDate}</strong>.
          </p>
        </div>
      </div>
    </div>
  )
}

export function AddedToExistingAutoshipConfirmationModal({
  discountedPriceDetails,
  existingPlanFrequency,
  onClose,
  planId,
  planName = '',
  productHasAutoshipRecurringDiscount,
  productImageUrl,
  productPrice,
  productName,
  productQuantity,
  variantLabel,
  upcomingASDeliveryDate,
}: AddedToExistingAutoshipConfirmationModalProps) {
  const { mobile } = useMobileCheck()

  return (
    <>
      <ModalHeader
        className="!bg-white md:!bg-gray-50"
        onClose={onClose}
        alignTitleLeft={mobile}
        title="Added to Autoship"
        renderTitle={(title) => (
          <h2 className="flex items-center justify-start space-x-2 text-2xl font-semibold text-coolGray md:justify-center">
            <StandardIcon icon="check-light" alt="" width={26} height={26} />
            <span>{title}</span>
          </h2>
        )}
      />
      <ModalContent className="overflow-x-hidden !bg-white md:!bg-gray-50 lg:max-h-[85vh]">
        <div className="grid grid-cols-12 gap-y-3 !bg-white md:p-4">
          <ExistingAutoshipPlanDetails
            className="md:hidden"
            planId={planId}
            planName={planName}
            upcomingASDeliveryDate={upcomingASDeliveryDate}
          />

          <div className="col-span-12 rounded border border-coolGray-200 p-2 md:col-span-6 md:border-0 md:px-4">
            <div className="grid grid-cols-12 gap-4 p-2">
              <div className="col-span-6 md:col-span-4">
                <div className="relative h-[8rem] w-full">
                  <Image src={productImageUrl} alt="" layout="fill" objectFit="contain" />
                </div>
              </div>
              <div className="col-span-12 flex flex-col space-y-3 text-sm font-semibold md:col-span-8 md:text-base">
                <p className="line-clamp-2 text-base text-pebble md:text-lg">{productName}</p>
                <p className="text-sm text-coolGray">
                  {productQuantity} x {variantLabel}
                </p>
                {productHasAutoshipRecurringDiscount && (
                  <div className="flex space-x-1 text-sm text-pebble">
                    <p>Price:</p>
                    <p className="text-pebble-750 line-through">{formatCurrency(productPrice)}</p>
                  </div>
                )}
                <div
                  className={clsx(
                    'flex space-x-1',
                    productHasAutoshipRecurringDiscount && '!-mt-0.5'
                  )}
                >
                  <p className="inline-flex space-x-1">
                    <StandardIcon icon="autoship" alt="" width={16} height={16} />
                    <span className="text-base font-extrabold text-plum">Autoship:</span>
                  </p>
                  <p className="text-pepper">
                    <span className="text-base">
                      {formatCurrency(
                        productHasAutoshipRecurringDiscount
                          ? discountedPriceDetails?.total
                          : productPrice
                      )}
                    </span>
                    {productHasAutoshipRecurringDiscount ? (
                      <span className="text-sm">
                        &nbsp;({discountedPriceDetails?.percentage}% off)
                      </span>
                    ) : null}
                  </p>
                </div>
                <p className="max-w-max rounded-md bg-plum px-2 py-1.5 text-white">
                  <span className="font-extrabold">Frequency:</span>&nbsp;
                  {existingPlanFrequency}
                </p>
              </div>
            </div>
          </div>

          <ExistingAutoshipPlanDetails
            className="hidden md:block"
            planId={planId}
            planName={planName}
            upcomingASDeliveryDate={upcomingASDeliveryDate}
          />
        </div>
      </ModalContent>
    </>
  )
}
