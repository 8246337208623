import type { FlattenedGQLCategoryTreeItem } from '../../../utils/categories'
import { getFlattenedGQLTree } from '../../../utils/categories'
import type { BigCommerceGQLCategoryTree3LevelsDeep } from '../../api/bigcommerce/graphql/catalog'
import type { BigCommerceProductCategory } from '../../api/bigcommerce/graphql/product/types'

/**
 * Finds the product.categories in the flattened entire category tree in it's BC Admin panel sort order
 * and returns those treeItems w/ primary on top and the rest in sort order.
 * These possible breadcrumb treeItems can be used on the client-side to show the previous viewed category
 * if it is assigned to the product. See implementation component for more details.
 * @param categories
 * @param categoryTree
 * @returns FlattenedGQLCategoryTreeItem[]
 */
export function getPossibleBreadcrumbCategories(
  categories: BigCommerceProductCategory[],
  categoryTree: BigCommerceGQLCategoryTree3LevelsDeep
) {
  // This tree is pre-arranged in BC Sort Order
  const flattenedGqlCats = getFlattenedGQLTree(categoryTree)

  const possibleBreadcrumbCategories = flattenedGqlCats.reduce(
    (matches: FlattenedGQLCategoryTreeItem[], treeItem: FlattenedGQLCategoryTreeItem) => {
      const productCategoryMatch = categories.find(({ entityId }) => entityId === treeItem.entityId)
      if (productCategoryMatch) {
        matches.push({
          ...treeItem,
        })
      }

      return matches
    },
    []
  )

  return possibleBreadcrumbCategories || null
}
