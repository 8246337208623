import { useRouter } from 'next/router'
import { useMemo, useState } from 'react'
import routes from '../lib/routes'
import useAuthStateQuery from './useAuthStateQuery'

export default function useRedirectToCheckout() {
  const router = useRouter()
  const { data: customer } = useAuthStateQuery()
  const isLoggedIn = useMemo(() => Boolean(customer?.id), [customer?.id])

  const [redirecting, setRedirecting] = useState(false)

  const handleCheckoutRedirect = useMemo(() => {
    return () => {
      setRedirecting(true)
      return router.push(routes.checkout())
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [isLoggedIn])

  return {
    handleCheckoutRedirect,
    redirecting,
  }
}
