import type {
  DropdownOption,
  OptionsList,
} from '../../../components/global/form/dropdown/OptionDropdown'
import type { SubscriptionPlan } from '../../../hooks/pageType/account/autoship/useSubscription'
import { formatAutoshipFrequencyOption } from './formatAutoshipFrequencyOption'

/**
 * A utility function to transform Autoship subscription plans to an `OptionsList`.
 *
 * @param subscriptionPlans - a JSON array of subscription plans
 * @returns a single selected frequency option and a single structured array of dropdown options
 */
export function transformSubscriptionPlansForDropdown(subscriptionPlans: Array<SubscriptionPlan>) {
  let selectedFrequencyOption = {} as DropdownOption
  const options = subscriptionPlans.map((plan, index) => {
    const option = {
      name: String(plan.tags?.[0]?.name),
      value: String(plan.uuid),
      sublabel: formatAutoshipFrequencyOption(plan.frequency),
      isSubscriptionPlan: true,
      subscriptionPlanShipOnDate: plan.shipOn,
      available: true,
    }

    if (index === 0) selectedFrequencyOption = option

    return option
  })

  return {
    selectedFrequencyOption,
    subscriptionPlanFrequencyOptionsList: [{ groupName: 'existing', options }] as OptionsList,
  }
}
