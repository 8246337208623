import clsx from 'clsx'
import { useState } from 'react'
import type { CartItemChangeEventLocation } from '../../../lib/clientOnly/analytics/sendAnalyticsCartItemChangeEvent'
import AddToCartModal from './AddToCartModal'

export type AddToCartButtonProps = {
  cartItemChangeEventLocation?: CartItemChangeEventLocation
  productId: number | string
  overrideClassName?: string
  quantity?: number
  variantSku?: string
  withMargin?: boolean
}

export default function AddToCartButton({
  cartItemChangeEventLocation,
  productId,
  overrideClassName,
  quantity = 1,
  variantSku,
  withMargin = true,
}: AddToCartButtonProps) {
  const [showATCModal, setShowATCModal] = useState(false)

  const openATCModal = () => {
    setShowATCModal(true)
  }

  const closeATCModal = () => {
    setShowATCModal(false)
  }

  return (
    <>
      <button
        type="button"
        className={clsx(
          overrideClassName ||
            'no-webkit-appearance focus:outline-none w-full rounded-md border-2 border-plum py-2 text-center text-sm font-bold leading-loose text-plum transition focus:ring-2 focus:ring-primaryLegacy-light sm:text-base',
          withMargin && '!mt-3 !mb-1.5 md:!mt-2'
        )}
        onClick={openATCModal}
      >
        Add to Cart
      </button>
      {showATCModal ? (
        <AddToCartModal
          cartItemChangeEventLocation={cartItemChangeEventLocation}
          onClose={closeATCModal}
          productId={productId}
          quantity={quantity}
          variantSku={variantSku}
        />
      ) : null}
    </>
  )
}
