import type { UseAutoshipApiMutationOptions } from './useAutoshipApiMutation'
import { useAutoshipApiMutation } from './useAutoshipApiMutation'

type Args = {
  planId?: string
  productQuantity?: number
  productSku?: string
}

export default function useAddPlanItemMutation(
  options?: Omit<UseAutoshipApiMutationOptions<Args>, 'transformArgs' | 'method'>
) {
  return useAutoshipApiMutation<Args>((args) => `/cw-api/autoship/plans/${args.planId}/items`, {
    method: 'POST',
    transformArgs: (args) => ({
      quantity: args.productQuantity,
      sku: args.productSku,
    }),
    ...options,
  })
}
