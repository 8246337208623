import routes from '../lib/routes'
import type { ApiGetProductCombined } from '../pages/api/product/combined/[productId]'
import { useApiQuery } from './useApiQuery'

export default function useProductCombined({ id }: { id: string }) {
  return useApiQuery<ApiGetProductCombined>(routes.api.getProductCombined({ id }), {
    revalidateOnFocus: false,
    keepPreviousData: true,
  })
}
