import type { AutoshipFrequencyOptions } from './types'

export const AUTOSHIP_FREQUENCY_OPTIONS: AutoshipFrequencyOptions = {
  day: [
    {
      uuid: 'ca584ce4-0727-43ab-8e15-2f69e09d54ac',
      type: 'day',
      value: 7,
    },
    {
      uuid: '3fa16755-2668-41d4-aa5d-e2d2302c4a21',
      type: 'day',
      value: 14,
    },
    {
      uuid: '60a0deff-d868-46e3-8c5b-edbd7ff1dbcd',
      type: 'day',
      value: 30,
      is_default: true,
    },
    {
      uuid: 'f6f6fd75-1d98-4739-b560-5f60da1159cc',
      type: 'day',
      value: 45,
    },
    {
      uuid: 'b4bade47-f078-45ad-a5f6-6ef25887d4dd',
      type: 'day',
      value: 60,
    },
    {
      uuid: '950cce8e-167f-4389-a66b-8d4ef8b0c422',
      type: 'day',
      value: 75,
    },
    {
      uuid: '66425542-9b70-4660-9af4-18f19c989407',
      type: 'day',
      value: 90,
    },
  ],
  week: [
    {
      uuid: '8b3f561b-9e71-4f7e-b5e2-3f542f011ef2',
      type: 'week',
      value: 1,
    },
    {
      uuid: 'd2559c4e-1a77-4432-b4e1-6db50f554ae7',
      type: 'week',
      value: 2,
    },
    {
      uuid: '52da1839-edc3-44dc-ad2b-184ea826dd00',
      type: 'week',
      value: 3,
    },
    {
      uuid: '75bd3e34-ed72-4c25-b35b-967a799bf267',
      type: 'week',
      value: 4,
    },
    {
      uuid: '0f1d360f-1308-4099-af22-103ce2b7ee9a',
      type: 'week',
      value: 5,
    },
    {
      uuid: '4c3bc738-041a-42a5-b3c6-97729dcbea8e',
      type: 'week',
      value: 6,
    },
    {
      uuid: '1369b095-7136-4172-9bad-264845443618',
      type: 'week',
      value: 7,
    },
    {
      uuid: '15611f8f-12d4-4b1e-878f-c4abf0a1e851',
      type: 'week',
      value: 8,
    },
    {
      uuid: '3d4d2f6c-378b-49a7-b9a7-2954988756d2',
      type: 'week',
      value: 9,
    },
    {
      uuid: '31552b35-92b4-46e7-add2-42a8f82b6cef',
      type: 'week',
      value: 10,
    },
    {
      uuid: '7a23bc1d-4a23-4028-b347-961596000e07',
      type: 'week',
      value: 11,
    },
    {
      uuid: 'a5569440-1b26-4447-afdd-5f8b94369387',
      type: 'week',
      value: 12,
    },
  ],
  month: [
    {
      uuid: 'f701886b-4efd-4850-a7ed-8c73d681da7e',
      type: 'month',
      value: 1,
    },
    {
      uuid: '48bb891c-7648-48d1-b2f5-53272b5e80e0',
      type: 'month',
      value: 2,
    },
    {
      uuid: '75c23ab3-290a-40bf-9b65-d2216e2c3562',
      type: 'month',
      value: 3,
    },
    {
      uuid: '553fe149-79d6-4779-b517-4a9e25a3c863',
      type: 'month',
      value: 4,
    },
    {
      uuid: 'cc83b750-988d-4142-8321-c2ed8c572327',
      type: 'month',
      value: 5,
    },
    {
      uuid: '55a59418-3a3c-48eb-8b2d-5b820efbc6c9',
      type: 'month',
      value: 6,
    },
  ],
}
