import clsx from 'clsx'
import { ChevronIcon } from '../../icons/ChevronRightIcon'
import type { DropdownOption, OptionDropdownProps, RenderButtonLabel } from './OptionDropdown'

export type OptionDropdownFieldProps = Pick<
  OptionDropdownProps,
  'isHighlighted' | 'inputName' | 'isLoading' | 'renderButtonLabel' | 'required'
> & {
  disabled: boolean
  handleClick: () => void
  selectedOption?: DropdownOption
  totalOptions: number
}

function DefaultRenderButtonLabel({ isLoading, selectedOption, totalOptions }: RenderButtonLabel) {
  return (
    <>
      <p
        className={`font-semibold ${
          selectedOption?.available === false ? 'text-coolGray-600 line-through' : ''
        }`}
      >
        {selectedOption?.name || 'Select an option...'}
      </p>
      {!isLoading ? (
        <p className="text-left text-sm underline filter">See all {totalOptions} options</p>
      ) : (
        <p className="h-5 animate-pulse bg-coolGray-200" />
      )}
    </>
  )
}

export function OptionDropdownField({
  disabled,
  handleClick,
  inputName,
  isHighlighted,
  isLoading,
  renderButtonLabel = DefaultRenderButtonLabel,
  required,
  selectedOption,
  totalOptions,
}: OptionDropdownFieldProps) {
  return (
    <button
      type="button"
      disabled={disabled}
      className={clsx(
        'flex w-full items-center justify-between rounded-md border text-left text-pebble',
        isHighlighted ? 'border-red-light bg-[#FDF7F4]' : 'border-coolGray-400 bg-white',
        disabled && 'cursor-not-allowed'
      )}
      onClick={handleClick}
    >
      <input
        className="sr-only invisible"
        value={selectedOption?.value}
        name={`${inputName}Hidden`}
        required={required}
      />
      <div className="m-2 w-full flex-col overflow-hidden text-left">
        {renderButtonLabel({ isLoading, selectedOption, totalOptions })}
      </div>
      <div className={clsx('m-2', isHighlighted && 'text-red-light')}>
        <ChevronIcon className="h-3" />
      </div>
    </button>
  )
}
