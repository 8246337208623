/**
 * A helper function to determine if a unit price is greater than 0 (0.00)
 * and it's also not equal to a product's price.
 *
 * @param unitPrice
 * @param price
 */
export function unitPricingHasValue(unitPrice?: number | null, price?: number | null) {
  const unitPricing = parseFloat(Number(unitPrice || 0).toFixed(2))
  const productPricing = parseFloat(Number(price || 0).toFixed(2))
  return unitPricing !== 0 && unitPricing !== productPricing
}

export default function calculateUnitPrice(variant: {
  bin_picking_number?: string | null
  price?: number | null
}) {
  const price = variant?.price
  const binPickingNumber = variant?.bin_picking_number
  const units = binPickingNumber ? parseInt(binPickingNumber) : undefined
  const unitPrice = price && units && !Number.isNaN(units) ? price / units : undefined
  const unitPriceValue = unitPrice ? unitPricingHasValue(unitPrice, price) : undefined

  return unitPriceValue ? unitPrice : undefined
}
