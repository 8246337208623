import breakpoints from '../lib/breakpoints'
import useWindowSize from './useWindowSize'

export function useMobileCheck() {
  const { width } = useWindowSize()

  const isSmallerThanSm = !!(width && width < breakpoints['sm'])
  const isSmallerThanMd = !!(width && width < breakpoints['md'])
  const isSmallerThanLg = !!(width && width < breakpoints['lg'])
  const isSmallerThanXl = !!(width && width < breakpoints['xl'])
  const isSmallerThan2xl = !!(width && width < breakpoints['2xl'])

  return {
    mobile: isSmallerThanMd, // legacy

    isSmallerThanSm,
    isSmallerThanMd,
    isSmallerThanLg,
    isSmallerThanXl,
    isSmallerThan2xl,
  }
}
