import { useQuery } from '@tanstack/react-query'
import { useNProgress } from '../../../../lib/clientOnly/nprogress'

export type AutoshipApiQueryOptions<Data = any> = {
  enabled?: boolean
  onSuccess?: (data: Data) => void
  onError?: (error: Error) => void
  method?: 'GET' | 'PUT' | 'POST' | 'DELETE'
  body?: any
}

export function useAutoshipApiQuery<Data = any>(
  path: string,
  { enabled = true, method = 'GET', body = undefined }: AutoshipApiQueryOptions<Data> = {}
) {
  const nprogress = useNProgress()

  return useQuery<Data>(
    [path],
    async () => {
      nprogress.inc()

      const response = await fetch(`/cw-api${path}`, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: body ? JSON.stringify(body) : null,
      })

      if (!response.ok) {
        if (response.status === 401) {
          await fetch('/api/refresh')
        }

        throw new Error(`Error ${response.status}`)
      }

      const json = await response.json()

      if (json?.code && json?.code !== 200) {
        throw new Error(json.message)
      }

      return json
    },
    {
      enabled,
      onSettled: () => nprogress.dec(),
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: true,
    }
  )
}
