import useWindowSize from '../../../../hooks/useWindowSize'
import breakpoints from '../../../../lib/breakpoints'

const RECOMMENDATIONS_WIDGET_CARD_WIDTH = {
  default: 10,
  sm: 12,
  md: 16,
}

const RECOMMENDATIONS_WIDGET_CARD_MARGIN = {
  default: 0.25,
  sm: 0.5,
  md: 0.5,
}

export function useRecommendationsWidgetCardDimensions(widths?, margins?) {
  const { width } = useWindowSize()

  const widthSetting = widths || RECOMMENDATIONS_WIDGET_CARD_WIDTH
  const marginSetting = margins || RECOMMENDATIONS_WIDGET_CARD_MARGIN

  if (width) {
    if (width >= breakpoints.md) {
      return {
        width: widthSetting.md,
        margin: marginSetting.md,
      }
    }

    if (width >= breakpoints.sm) {
      return {
        width: widthSetting.sm,
        margin: marginSetting.sm,
      }
    }
  }

  return {
    width: widthSetting.default,
    margin: marginSetting.default,
  }
}
