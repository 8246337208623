import type {LineItem, LineItemInputOption} from '@corewell/cart'
import type { ChangeEvent, ReactNode } from 'react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSubscriptions } from '../../../../hooks/pageType/account/autoship/useSubscriptions'
import { useAddToCartMutation } from '../../../../hooks/useAddToCartMutation'
import useAuthStateQuery from '../../../../hooks/useAuthStateQuery'
import useCartQuery from '../../../../hooks/useCartQuery'
import useCustomerHasAutoshipSubscriptions from '../../../../hooks/useCustomerHasAutoshipSubscriptions'
import useCustomerQuery from '../../../../hooks/useCustomerQuery'
import { useFormInputFocus } from '../../../../hooks/useFormInputFocus'
import useWindowSize from '../../../../hooks/useWindowSize'
import breakpoints from '../../../../lib/breakpoints'
import getGTMItemCategoriesFromBreadcrumbs from '../../../../lib/clientOnly/analytics/gtm/getGTMItemCategoriesFromBreadcrumbs'
import useGTMAddToCartEvent from '../../../../lib/clientOnly/analytics/gtm/useGTMAddToCartEvent'
import type { CartItemChangeEventLocation } from '../../../../lib/clientOnly/analytics/sendAnalyticsCartItemChangeEvent'
import { AUTOSHIP_FREQUENCY_OPTIONS } from '../../../../lib/constants/AUTOSHIP_FREQUENCY_OPTIONS'
import type { AutoshipDiscounts } from '../../../../lib/constants/types'
import type { BigCommerceProduct } from '../../../../lib/serverOnly/api/bigcommerce/graphql/product/types'
import { getPossibleBreadcrumbCategories } from '../../../../lib/serverOnly/pageTypes/product/get_possible_breadcrumb_categories'
import { transformAutoshipOptionsForDropdown } from '../../../../lib/utils/autoship/transformAutoshipOptionsForDropdown'
import { transformSubscriptionPlansForDropdown } from '../../../../lib/utils/autoship/transformSubscriptionPlansForDropdown'
import compact from '../../../../lib/utils/compact'
import { formatDate } from '../../../../lib/utils/date'
import type { DropdownOption, OptionsList } from '../../../global/form/dropdown/OptionDropdown'
import { useMainLayoutStaticProps } from '../../../global/layouts/MainLayout/MainLayoutContext'
import type { ModalSizeOptions } from '../../../global/modal/Modal'
import Modal from '../../../global/modal/Modal'
import { AddedToCartModal } from '../AddedToCartModal'
import { AddedToExistingAutoshipConfirmationModal } from '../AddedToExistingAutoshipConfirmationModal'
import { AddToCartForm } from '../AddToCartForm'
import { AddToExistingAutoshipFormModal } from '../AddToExistingAutoshipFormModal'
import { saveItemToLocalStorage } from 'lib/clientOnly/analytics/gtm/util'

export type AddToCartComponentProps = {
  autoshipDiscounts?: AutoshipDiscounts
  binPickingNumber?: string | null
  cartItemChangeEventLocation?: CartItemChangeEventLocation
  className?: string
  children?: (props: {
    addingToCart?: boolean
    disabled?: boolean
    formError?: string
  }) => ReactNode
  defaultQuantity?: number
  disabled?: boolean
  enableCtaButtons?: boolean
  hideAutoshipPricing: boolean
  onCloseAddedToCartModal?: () => void
  price?: number
  productHasAutoshipRecurringDiscount?: boolean
  product: BigCommerceProduct
  selectedVariantId: number | null
  selectedVariantInStock?: boolean
  showEstimatedDeliveryDate?: boolean
  showStockIndicator?: boolean
}

export type PurchaseOptionType = 'atc-standard' | 'atc-autoship'

export default function AddToCartComponent({
  autoshipDiscounts,
  binPickingNumber,
  children,
  selectedVariantId,
  defaultQuantity,
  disabled = false,
  enableCtaButtons = true,
  hideAutoshipPricing,
  onCloseAddedToCartModal,
  product,
  productHasAutoshipRecurringDiscount = false,
  price,
  ...rest
}: AddToCartComponentProps) {
  const { data: cart } = useCartQuery()
  const { data: customer, isLoading: verifyingCustomerSession } = useAuthStateQuery()

  const customerQuery = useCustomerQuery()

  const customerLoggedin = !!customer
  const { data: allSubscriptionPlans, fetchStatus } = useSubscriptions({
    enabled: customerLoggedin && !hideAutoshipPricing,
  })

  const loadingSubscriptionPlans = fetchStatus === 'fetching'

  const { width } = useWindowSize()
  const { formRef, applyFormFocus } = useFormInputFocus()

  const activeSubscriptionPlans = useMemo(
    () => allSubscriptionPlans?.filter((plan) => plan.status === 'upcoming'),
    [allSubscriptionPlans]
  )
  const hasActiveSubscriptionPlans = (activeSubscriptionPlans?.length ?? 0) > 0
  const customerHasAutoshipSubscriptions = useCustomerHasAutoshipSubscriptions()

  // based upon the customer's segment group, this determines the product's pricing
  // discount when added to cart (the discount logic is handled by the AS engine, we
  // just need to make sure it's sync on the client)
  let discountedPriceDetails: { total: number; percentage: number } | undefined
  if (!customerHasAutoshipSubscriptions && !hideAutoshipPricing) {
    discountedPriceDetails = autoshipDiscounts?.firstDiscount
  } else if (productHasAutoshipRecurringDiscount && !hideAutoshipPricing) {
    discountedPriceDetails = autoshipDiscounts?.reccuringDiscount
  }

  const { categoryTree } = useMainLayoutStaticProps()

  const {
    mutateAsync: addToCart,
    isLoading: addingToCart,
    error,
  } = useAddToCartMutation({
    onSuccess: (data, args) => {
      applyFormFocus()
      const newItem = data.newItems[0];

      if (newItem) {
        setNewlyAddedLineItem(newItem)
      }

      const categories = getPossibleBreadcrumbCategories(
        compact(product.categories?.edges?.map((e) => e.node) || []),
        categoryTree
      )

      const addedToCartProduct = {
        item_id: product.entityId?.toString(),
        item_name: product.name || '',
        price,
        item_brand: product.brand?.name,
        image_url: selectedVariant?.node?.defaultImage?.urlOriginal,
        quantity: args?.quantity || 1,
        item_variant: selectedVariantId?.toString(),
        item_sku: selectedVariant?.node?.sku,
        is_autoship: isAutoshipPurchaseType,
        ...getGTMItemCategoriesFromBreadcrumbs(categories[0]),
      }
      saveItemToLocalStorage(addedToCartProduct)

      pushGTMAddToCartEvent({
        value: price || 0,
        items: [addedToCartProduct],
        customer: customerQuery.data!,
      })
    },
  })

  const selectedVariant = product.variants?.edges?.find(
    ({ node: variant }) => variant?.entityId === selectedVariantId
  )

  function getInitialQuantityState() {
    if (defaultQuantity) return defaultQuantity.toString()

    return (product.minPurchaseQuantity || 0) > 0 ? `${product.minPurchaseQuantity}` : '1'
  }

  const [quantity, setQuantity] = useState(getInitialQuantityState())

  const { defaultFrequencyOption, defaultFrequencyOptionsList } = useMemo(
    () => transformAutoshipOptionsForDropdown(AUTOSHIP_FREQUENCY_OPTIONS),
    []
  )

  const [selectedFrequencyOptionValue, setSelectedFrequencyOptionValue] = useState<
    DropdownOption | undefined
  >()
  const [dropdownOptionsList, setDropdownOptionsList] = useState<OptionsList>([])
  const [selectedPurchaseType, setSelectedPurchaseType] =
    useState<PurchaseOptionType>('atc-standard')

  // atc modal
  const [newlyAddedLineItem, setNewlyAddedLineItem] = useState<LineItem | null>(null)
  const [addToCartModalSize, setAddToCartModalSize] = useState<ModalSizeOptions>('large')

  // existing AS form modal
  const [displayAddToExistingASFormModal, setDisplayAddToExistingASFormModal] = useState(false)
  const [addToExistingASFormModalSize, setAddToExistingASFormModalSize] =
    useState<ModalSizeOptions>('small')

  // existing AS confirmation modal
  const [displayAddedToExistingASConfirmationModal, setDisplayAddedToExistingASConfirmationModal] =
    useState(false)
  const [addedToExistingASConfirmationModalSize, setAddedToExistingASConfirmationModalSize] =
    useState<ModalSizeOptions>('large')

  const productQuantity = parseInt(quantity, 10)
  const productImageUrl =
    selectedVariant?.node?.defaultImage?.urlOriginal ||
    product.images?.edges?.[0]?.node?.urlOriginal ||
    ''
  const productName = product.name || ''
  const planName = selectedFrequencyOptionValue?.name

  // the BC selected product option type id and value
  const selectedProductOption = selectedVariant?.node?.productOptions?.edges?.[0]?.node
  const selectedProductOptionValue = selectedProductOption?.values?.edges?.[0]?.node
  const selectedProductOptionValueEntityId = selectedProductOptionValue?.entityId

  const isAutoshipPurchaseType = selectedPurchaseType === 'atc-autoship'
  const isExistingAutoshipPlan = Boolean(
    selectedFrequencyOptionValue?.isSubscriptionPlan && isAutoshipPurchaseType
  )

  // the BC product's Autoship Frequency's "TextFieldOption" id
  const selectedFrequencyOptionId = product.productOptions?.edges?.[1]?.node?.entityId
  // the selected Autoship frequency uuid value that correlates to one of the "AUTOSHIP_FREQUENCY_OPTIONS"
  const frequencyOptionValue = selectedFrequencyOptionValue?.value

  // these option selections will determine whether or not the product was added to cart as an OTP or AS
  // OTP only has product id and product variant id
  // AS has product id and product variant id AND AS textfield id with the AS frequency uuid
  const optionSelections: LineItemInputOption[] = []

  // appends the product's id with the product variant's id to the cart
  if (selectedProductOption?.entityId && selectedProductOptionValueEntityId) {
    optionSelections.push({
      nameId: selectedProductOption.entityId,
      valueId: selectedProductOptionValueEntityId,
    })
  }

  // appends the product's Autoship textfield frequency id with the selected Autoship
  // frequency uuid to the cart (if the "Add to Autoship" button was clicked)
  if (
    selectedFrequencyOptionId &&
    isAutoshipPurchaseType &&
    frequencyOptionValue &&
    !isExistingAutoshipPlan
  ) {
    optionSelections.push({
      nameId: selectedFrequencyOptionId,
      value: frequencyOptionValue,
    })
  }

  // selected frequency option is an existing AS
  const upcomingASDeliveryDate = formatDate(
    selectedFrequencyOptionValue?.subscriptionPlanShipOnDate || '',
    'MM Do'
  )

  const onPurchaseTypeChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSelectedPurchaseType(event.target.value as PurchaseOptionType)
  }, [])

  const onSelectedFrequencyValueChange = useCallback((selection: DropdownOption) => {
    setSelectedFrequencyOptionValue(selection)
  }, [])

  const handleAddProductVariantToCart = async () => {
    if (
      !product.entityId ||
      !productQuantity ||
      !selectedVariantId ||
      optionSelections.length < 1
    ) {
      return
    }

    await addToCart({
      quantity: productQuantity,
      productId: product.entityId,
      options: optionSelections,
      productName: product.name,
    })
  }

  const handleAddToCartFormSubmit = async () => {
    if (isExistingAutoshipPlan) {
      setDisplayAddToExistingASFormModal(true)
    } else {
      await handleAddProductVariantToCart()
    }
  }

  // close atc modal
  const onATCModalClose = () => setNewlyAddedLineItem(null)

  // close existing AS form modal
  const onAddToExistingASFormModalClose = () => setDisplayAddToExistingASFormModal(false)

  // close existing AS confirmation modal
  const onAddedToExistingASConfirmationModalClose = () =>
    setDisplayAddedToExistingASConfirmationModal(false)

  // open existing AS confirmation modal
  const onAddedToExistingASConfirmationModalOpen = () =>
    setDisplayAddedToExistingASConfirmationModal(true)

  useEffect(() => {
    const isDrawer = width && width <= breakpoints.sm
    const newLargeOrDrawerModalSize = isDrawer ? 'drawer' : 'large'

    // atc modal
    if (addToCartModalSize !== newLargeOrDrawerModalSize) {
      setAddToCartModalSize(newLargeOrDrawerModalSize)
    }

    // existing AS form modal
    const newSmallOrDrawerModalSize = isDrawer ? 'drawer' : 'small'
    if (addToExistingASFormModalSize !== newSmallOrDrawerModalSize) {
      setAddToExistingASFormModalSize(newSmallOrDrawerModalSize)
    }

    // existing AS confirmation modal
    if (addedToExistingASConfirmationModalSize !== newLargeOrDrawerModalSize) {
      setAddedToExistingASConfirmationModalSize(newLargeOrDrawerModalSize)
    }
  }, [
    width,
    addToCartModalSize,
    addToExistingASFormModalSize,
    addedToExistingASConfirmationModalSize,
  ])

  // set exsiting AS and default frequency options when a logged in
  // customer has existing Autoship sub plans
  useEffect(() => {
    const isCustomerWithExistingAutoships =
      customerLoggedin && hasActiveSubscriptionPlans && !loadingSubscriptionPlans

    if (isCustomerWithExistingAutoships && activeSubscriptionPlans) {
      const { selectedFrequencyOption, subscriptionPlanFrequencyOptionsList } =
        transformSubscriptionPlansForDropdown(activeSubscriptionPlans)

      setSelectedFrequencyOptionValue(selectedFrequencyOption)
      setDropdownOptionsList([
        ...subscriptionPlanFrequencyOptionsList,
        ...defaultFrequencyOptionsList,
      ])
    }
  }, [
    activeSubscriptionPlans,
    customerLoggedin,
    defaultFrequencyOptionsList,
    hasActiveSubscriptionPlans,
    loadingSubscriptionPlans,
  ])

  // NOTE: The "isCustomerWithoutActiveAutoships" logic is currently redundant since
  // "isLoadingCustomer" and "hasActiveSubscriptionPlans" aren't updated at the same time; therefore
  // the "isGuest" logic will always be true for 1 render cycle
  // set default frequency options when a customer is a guest or a registered user without AS plans
  useEffect(() => {
    const isLoadingCustomer = loadingSubscriptionPlans && verifyingCustomerSession
    const isGuest = !customerLoggedin && !isLoadingCustomer
    const isCustomerWithoutActiveAutoships =
      customerLoggedin && !hasActiveSubscriptionPlans && !isLoadingCustomer

    if (isGuest || isCustomerWithoutActiveAutoships) {
      setSelectedFrequencyOptionValue(defaultFrequencyOption)
      setDropdownOptionsList(defaultFrequencyOptionsList)
    }
  }, [
    customerLoggedin,
    defaultFrequencyOption,
    defaultFrequencyOptionsList,
    hasActiveSubscriptionPlans,
    loadingSubscriptionPlans,
    verifyingCustomerSession,
  ])

  const onHideModal = (modal: 'added-to-cart' | 'added-to-existing-autoship') => {
    return () => {
      switch (modal) {
        case 'added-to-cart':
          onATCModalClose()
          break
        case 'added-to-existing-autoship':
          onAddedToExistingASConfirmationModalClose()
          break
      }

      onCloseAddedToCartModal?.()
    }
  }

  const pushGTMAddToCartEvent = useGTMAddToCartEvent()

  return (
    <>
      <AddToCartForm
        ref={formRef}
        addingToCart={addingToCart}
        autoshipDiscounts={autoshipDiscounts}
        autoshipFrequencyOptionsList={dropdownOptionsList}
        disabled={disabled}
        error={error?.message}
        hideAutoshipPricing={!selectedFrequencyOptionId || hideAutoshipPricing}
        loadingSubscriptionPlans={verifyingCustomerSession || loadingSubscriptionPlans}
        productHasAutoshipRecurringDiscount={productHasAutoshipRecurringDiscount}
        quantity={quantity}
        min={product.minPurchaseQuantity || undefined}
        max={product.maxPurchaseQuantity || undefined}
        onQuantityChange={setQuantity}
        onSelectedFrequencyValueChange={onSelectedFrequencyValueChange}
        onPurchaseTypeChange={onPurchaseTypeChange}
        onSubmit={handleAddToCartFormSubmit}
        selectedFrequency={selectedFrequencyOptionValue}
        selectedPurchaseType={selectedPurchaseType}
        selectedVariantId={selectedVariantId}
        enableCtaButtons={enableCtaButtons}
        binPickingNumber={binPickingNumber}
        discountedPriceDetails={discountedPriceDetails}
        price={price}
        {...rest}
      >
        {children?.({ addingToCart, disabled, formError: error?.message })}
      </AddToCartForm>
      {newlyAddedLineItem && (
        <Modal
          size={addToCartModalSize}
          show={!!newlyAddedLineItem}
          onHide={onHideModal('added-to-cart')}
        >
          <AddedToCartModal
            cart={cart ?? null}
            discountedPriceDetails={discountedPriceDetails}
            isExistingAutoshipPlan={isExistingAutoshipPlan}
            onClose={onHideModal('added-to-cart')}
            planId={frequencyOptionValue}
            planName={planName}
            productImageUrl={productImageUrl}
            productName={productName}
            variantLabel={selectedProductOptionValue?.label}
            lineItem={newlyAddedLineItem}
          />
        </Modal>
      )}
      {displayAddToExistingASFormModal && (
        <Modal
          size={addToExistingASFormModalSize}
          show={displayAddToExistingASFormModal}
          onHide={onAddToExistingASFormModalClose}
        >
          <AddToExistingAutoshipFormModal
            handleAddProductVariantToCart={handleAddProductVariantToCart}
            planId={frequencyOptionValue}
            productQuantity={productQuantity}
            productSku={selectedVariant?.node?.sku}
            onClose={onAddToExistingASFormModalClose}
            showAddedToExistingASConfirmationModal={onAddedToExistingASConfirmationModalOpen}
            upcomingASDeliveryDate={upcomingASDeliveryDate}
          />
        </Modal>
      )}
      {displayAddedToExistingASConfirmationModal && (
        <Modal
          size={addedToExistingASConfirmationModalSize}
          show={displayAddedToExistingASConfirmationModal}
          onHide={onHideModal('added-to-existing-autoship')}
        >
          <AddedToExistingAutoshipConfirmationModal
            discountedPriceDetails={discountedPriceDetails}
            existingPlanFrequency={String(selectedFrequencyOptionValue?.sublabel || '')}
            onClose={onHideModal('added-to-existing-autoship')}
            planId={frequencyOptionValue}
            planName={planName}
            productHasAutoshipRecurringDiscount={productHasAutoshipRecurringDiscount}
            productImageUrl={productImageUrl}
            productPrice={selectedVariant?.node?.prices?.price?.value}
            productName={productName}
            productQuantity={quantity}
            variantLabel={selectedProductOptionValue?.label}
            upcomingASDeliveryDate={upcomingASDeliveryDate}
          />
        </Modal>
      )}
    </>
  )
}
