import type {
  DropdownOption,
  OptionsList,
} from '../../../components/global/form/dropdown/OptionDropdown'
import type { AutoshipFrequencyOption, AutoshipFrequencyOptions } from '../../constants/types'
import { formatAutoshipFrequencyOption } from './formatAutoshipFrequencyOption'

/**
 * A utility function to transform a single Autoship frequency to a dropdown option.
 *
 * @param option - a single Autoship frequency option
 * @returns a transformed dropdown option
 */
export function mapAutoshipOption(option: AutoshipFrequencyOption) {
  return {
    name: formatAutoshipFrequencyOption(option, option.is_default),
    value: option.uuid,
    available: true,
  }
}
/**
 * A utility function to tranform Autoship JSON to `OptionDropdown` options list.
 *
 * @param OPTIONS - a JSON array of Autoship frequency options
 * @returns a single default frequency option and an array of default dropdown options
 */
export function transformAutoshipOptionsForDropdown(OPTIONS: AutoshipFrequencyOptions) {
  return Object.entries(OPTIONS).reduce(
    (acc, [key, optionSet]) => {
      const options = optionSet.map((option) => {
        const santizedOption = mapAutoshipOption(option)

        if (option.is_default) acc.defaultFrequencyOption = santizedOption
        return santizedOption
      })

      acc.defaultFrequencyOptionsList.push({ groupName: key, options })

      return acc
    },
    { defaultFrequencyOption: {} as DropdownOption, defaultFrequencyOptionsList: [] as OptionsList }
  )
}
