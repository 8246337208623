import type {LineItem} from '@corewell/cart'
import clsx from 'clsx'
import Image from 'next/legacy/image'
import { useRouter } from 'next/router'
import type { CartQueryData } from '../../../hooks/useCartQuery'
import { useMobileCheck } from '../../../hooks/useMobileCheck'
import { AUTOSHIP_FREQUENCY_OPTIONS } from '../../../lib/constants/AUTOSHIP_FREQUENCY_OPTIONS'
import routes from '../../../lib/routes'
import { findAutoshipFrequencyOptionByUUID } from '../../../lib/utils/autoship/flattenOrFindAutoshipFrequencyOptions'
import { formatAutoshipFrequencyOption } from '../../../lib/utils/autoship/formatAutoshipFrequencyOption'
import { formatCurrency } from '../../../lib/utils/currency'
import { Button } from '../../global/button/Button'
import CheckoutButton from '../../global/button/CheckoutButton'
import StandardIcon from '../../global/icons/StandardIcon'
import { ModalContent } from '../../global/modal/ModalContent'
import { ModalHeader } from '../../global/modal/ModalHeader'
import ShippingProgress from '../../global/ShippingProgress'
import ManageExistingAutoshipLink from './ManageExistingAutoshipLink'

export type AddedToCartModalProps = {
  cart: CartQueryData | null
  discountedPriceDetails?: { percentage?: number; total?: number }
  isExistingAutoshipPlan: boolean
  onClose: () => void
  planId?: string
  planName?: string
  productImageUrl: string
  productName: string
  variantLabel?: string | null
  viewCartHref?: string
  // The newly added line item just added to the cart, which we're now presenting in the modal
  lineItem: LineItem
}

export function AddedToCartModal({
  cart,
  discountedPriceDetails,
  isExistingAutoshipPlan = false,
  onClose,
  planId,
  planName,
  productImageUrl,
  productName,
  variantLabel,
  viewCartHref = routes.cart(),
  lineItem
}: AddedToCartModalProps) {
  const router = useRouter()
  const { mobile } = useMobileCheck()

  const cartCount = cart?.count
  const baseAmount = cart?.baseAmount

  const productQuantity = lineItem.quantity
  const productFrequencyOption = findAutoshipFrequencyOptionByUUID(
    AUTOSHIP_FREQUENCY_OPTIONS,
    lineItem.options[1]?.value || ''
  )

  const autoshipDiscountDetails = productFrequencyOption ? discountedPriceDetails : undefined
  const hasAutoshipDiscount = Boolean(autoshipDiscountDetails?.total)
  const totalAutoshipDiscountAmount = cart?.totalAutoshipDiscountAmount || 0

  return (
    <>
      <ModalHeader
        className="!bg-white md:!bg-gray-50"
        onClose={onClose}
        alignTitleLeft={mobile}
        title="Added to Cart"
        renderTitle={(title) => (
          <h2 className="text-coolGray flex flex-row items-center justify-start space-x-2 text-2xl font-semibold md:justify-center">
            <StandardIcon icon="check-light" alt="" width={26} height={26} />
            <span>{title}</span>
          </h2>
        )}
      />

      <ModalContent className="overflow-x-hidden !bg-white md:!bg-gray-50 lg:max-h-[85vh]">
        <div className="grid grid-cols-12 gap-y-3">
          <div className="col-span-12 bg-white md:col-span-6 md:px-4 md:py-4">
            <div className="grid grid-cols-12 gap-4 bg-white">
              <div className="col-span-4">
                <div className="relative h-[8rem] w-full">
                  <Image src={productImageUrl} alt="" layout="fill" objectFit="contain" />
                </div>
              </div>

              <div
                className={clsx(
                  'col-span-8 flex flex-col text-sm font-semibold md:text-base',
                  productFrequencyOption ? 'space-y-3' : 'justify-around'
                )}
              >
                <p className="text-pebble line-clamp-2 text-base md:text-lg">{productName}</p>
                <p className="text-coolGray text-sm">
                  {productQuantity} x {variantLabel}
                </p>
                <div
                  className={clsx(
                    'text-pebble flex space-x-1',
                    hasAutoshipDiscount ? 'text-sm' : 'text-base'
                  )}
                >
                  <p>Price:</p>
                  <p
                    className={clsx(
                      hasAutoshipDiscount && totalAutoshipDiscountAmount < 20
                        ? 'text-pebble-750 line-through'
                        : 'text-pepper'
                    )}
                  >
                    {formatCurrency(lineItem.listPrice)}
                  </p>
                </div>
                {productFrequencyOption ? (
                  <>
                    {hasAutoshipDiscount && totalAutoshipDiscountAmount < 20 ? (
                      <div className="!-mt-1 flex items-center space-x-1">
                        <p className="inline-flex space-x-1">
                          <StandardIcon icon="autoship" alt="" width={16} height={16} />
                          <span className="text-plum text-base font-extrabold">Autoship:</span>
                        </p>
                        <p className="text-pepper">
                          {formatCurrency(autoshipDiscountDetails?.total)}&nbsp;
                          <span className="text-sm">
                            ({autoshipDiscountDetails?.percentage}% off)
                          </span>
                        </p>
                      </div>
                    ) : null}
                    <p className="bg-plum max-w-max rounded-md px-2 py-1.5 text-white">
                      <span className="font-extrabold">Frequency:</span>&nbsp;
                      {formatAutoshipFrequencyOption(productFrequencyOption)}
                    </p>
                  </>
                ) : null}
              </div>
            </div>
            {isExistingAutoshipPlan ? (
              <div className="bg-plum mt-2 flex max-w-max flex-col space-y-0 rounded-md p-1.5 px-3 text-white">
                <p className="inline-flex space-x-1">
                  <StandardIcon icon="autoship-light" alt="" width={16} height={16} />
                  <span className="text-base font-extrabold text-white">Autoship</span>
                </p>
                <p className="text-sm md:text-base">
                  This product was added to your&nbsp;
                  <ManageExistingAutoshipLink className="text-white" planId={planId}>
                    {planName}
                  </ManageExistingAutoshipLink>
                </p>
              </div>
            ) : null}
          </div>

          <div className="col-span-12 md:col-span-6">
            <div className="bleed h-full space-y-3 bg-[#f3f1f3] px-4 py-4 sm:static sm:mx-0 sm:w-auto">
              <ShippingProgress
                baseAmount={baseAmount}
                cartCount={cartCount}
                autoshipDiscount={totalAutoshipDiscountAmount}
              />
              <div className="grid grid-cols-2 gap-4">
                <CheckoutButton extraClassName="leading-none" onClick={onClose} stretched={false} />

                <Button
                  onClick={() => {
                    onClose()
                    router.push(viewCartHref)
                  }}
                  className="border-coolGray text-coolGray rounded border-2 bg-white p-1 text-center text-xl font-bold leading-loose"
                >
                  View Cart
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ModalContent>
    </>
  )
}
