import Link from 'next/link'
import { useCallback, useEffect, useMemo, useState } from 'react'
import useProductCombined from '../../../hooks/useProductCombined'
import ANALYTICS_ACTIONS from '../../../lib/constants/ANALYTICS_ACTIONS'
import COMPANY_INFO from '../../../lib/constants/COMPANY_INFO'
import type { BigCommerceProductVariant } from '../../../lib/serverOnly/api/bigcommerce/v3/product/types'
import type { CalculatePricingResult } from '../../../lib/utils/pageType/product/calculatePricing'
import { calculatePricing } from '../../../lib/utils/pageType/product/calculatePricing'
import isVariantAvailable from '../../../lib/utils/pageType/product/isVariantAvailable'
import { Button } from '../../global/button/Button'
import { FormError } from '../../global/form/FormError'
import Modal from '../../global/modal/Modal'
import { ModalContent } from '../../global/modal/ModalContent'
import { ModalHeader } from '../../global/modal/ModalHeader'
import AddToCartComponent from '../product/AddToCartComponent/AddToCartComponent'
import GenericOptionDropdown from '../product/GenericOptionDropdown'
import type { AddToCartButtonProps } from './AddToCartButton'
import ProductImage from './ProductImage'
import ProductImageNotAvailable from './ProductImageNotAvailable'

export type AddToCartModalProps = AddToCartButtonProps & {
  onClose: () => void
}

export default function AddToCartModal({
  cartItemChangeEventLocation,
  onClose,
  productId,
  quantity,
  variantSku,
}: AddToCartModalProps) {
  const { data, isValidating, error: error } = useProductCombined({ id: String(productId) })
  const [selectedV3Variant, setSelectedV3Variant] = useState<
    BigCommerceProductVariant | undefined
  >()
  const [prices, setPrices] = useState<CalculatePricingResult | undefined>()

  const product = useMemo(() => data?.product, [data])
  const productVariantsV3 = useMemo(() => data?.productVariantsV3, [data])

  // product
  const productURL = product?.path
  const isProductOutOfStock = productVariantsV3?.every(
    ({ inventory_level, purchasing_disabled }) => inventory_level <= 0 || purchasing_disabled
  )

  // selected product variant
  const selectedV3VariantPrice = selectedV3Variant?.sale_price || selectedV3Variant?.price
  const selectedV3VariantName = selectedV3Variant?.option_values?.[0]?.label
  const selectedV3VariantAvailable = isVariantAvailable(selectedV3Variant)

  const filteredProductVariantsV3 = product?.variants?.edges?.map((variant) =>
    productVariantsV3?.find((v) => v.id === variant.node.entityId)
  )

  // TODO - Reduce complexity by enforcing a single param (variantId or sku) instead of combined
  const updateSelectedVariant = useCallback(
    (skuOrId?: string | number) => {
      const defaultVariant = product?.variants?.edges?.[0] || {}

      const { node: selectedVariant } =
        product?.variants?.edges?.find(
          ({ node }) => node?.entityId === skuOrId || node?.sku === skuOrId
        ) || defaultVariant

      const selectedV3Variant =
        productVariantsV3?.find((v) => v?.sku === selectedVariant?.sku) || productVariantsV3?.[0]

      setSelectedV3Variant(selectedV3Variant)
      setPrices(calculatePricing(selectedVariant.prices))
    },
    [product, productVariantsV3]
  )

  useEffect(() => {
    if (product && productVariantsV3 && !isValidating) {
      updateSelectedVariant(variantSku)
    }
  }, [isValidating, product, productVariantsV3, updateSelectedVariant, variantSku])

  if (error) {
    return (
      <Modal size="small" onHide={onClose}>
        <ModalHeader
          renderTitle={(title) => (
            <h2 className="text-center font-bold sm:text-xl md:text-2xl">{title}</h2>
          )}
          title="Oops! Something Went Wrong"
          onClose={onClose}
        />
        <ModalContent className="space-y-4 !bg-white !px-6 text-xl text-pebble md:text-lg">
          <p>Something went wrong and we are unable to load this item at the moment.</p>
          <p>
            Please try again in a few minutes or call our friendly customer service at&nbsp;
            <a className="underline" href={`tel:${COMPANY_INFO.phone.value}`}>
              {COMPANY_INFO.phone.displayValue}
            </a>
            .
          </p>
        </ModalContent>
      </Modal>
    )
  }

  return (
    <Modal size="small" onHide={onClose} rebuildTabListOnchange={[selectedV3Variant]}>
      <ModalHeader
        renderTitle={(title) => (
          <h2 className="text-center text-xl font-bold md:text-2xl">{title}</h2>
        )}
        title="Add to Cart"
        onClose={onClose}
      />
      <ModalContent className="!bg-white text-pebble">
        {!selectedV3Variant ? (
          <>
            <div className="h-32 w-full animate-pulse rounded-md bg-gray-300" />
            <div className="h-[78px] w-full animate-pulse rounded-md bg-gray-300" />
            <div className="space-y-4">
              <div className="h-[219px] w-full animate-pulse rounded-md bg-gray-300" />
              <div className="h-[3.5rem] w-full animate-pulse rounded-md bg-gray-300 lg:h-[3rem]" />
              <div className="h-[3.75rem] w-full animate-pulse rounded-md bg-gray-300 lg:h-[3.25rem]" />
            </div>
          </>
        ) : (
          <>
            <Link href={productURL || ''} prefetch={false}>
              <div className="flex h-32 rounded-md border border-pebble-250 p-2">
                {selectedV3Variant?.image_url ? (
                  <ProductImage src={selectedV3Variant?.image_url} altTitle="" />
                ) : (
                  <ProductImageNotAvailable />
                )}
                <div className="flex flex-1 flex-col justify-center space-y-1.5">
                  <h3 className="line-clamp-3 text-xl font-semibold text-pebble">
                    {product?.name}
                  </h3>
                  <p className="text-base text-coolGray">
                    {quantity} x {selectedV3VariantName}
                  </p>
                </div>
              </div>
            </Link>
            <GenericOptionDropdown
              defaultVariant={selectedV3Variant}
              handleChange={({ value }) => updateSelectedVariant(parseInt(value, 10))}
              isHighlighted={!isProductOutOfStock}
              productVariantsV3={filteredProductVariantsV3}
              purpleSelectionIndicator={false}
              useProductVariantIdAsValue
            />
            {product ? (
              <AddToCartComponent
                autoshipDiscounts={prices?.autoship}
                binPickingNumber={selectedV3Variant?.bin_picking_number}
                cartItemChangeEventLocation={cartItemChangeEventLocation}
                className="mt-4 lg:mt-0"
                defaultQuantity={quantity}
                disabled={!selectedV3VariantAvailable}
                enableCtaButtons={false}
                hideAutoshipPricing={!product.isAutoshipEligible}
                onCloseAddedToCartModal={onClose}
                product={product}
                productHasAutoshipRecurringDiscount={product.hasAutoshipRecurringDiscount}
                price={selectedV3VariantPrice}
                selectedVariantId={selectedV3Variant?.id}
                selectedVariantInStock={selectedV3VariantAvailable}
                showStockIndicator
              >
                {({ addingToCart, disabled, formError }) => (
                  <div className="mt-4 space-y-4">
                    <Button
                      size="sm"
                      type="button"
                      stretched
                      disabled={addingToCart || disabled}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      size="sm"
                      variant="fill"
                      stretched
                      disabled={addingToCart || disabled}
                      loading={addingToCart}
                    >
                      {addingToCart ? 'Adding...' : 'Continue'}
                    </Button>
                    {formError && (
                      <div className="text-lg">
                        <FormError
                          error="Oops, we are unable to add this item to your cart at the moment. Please try again later."
                          analyticsAction={ANALYTICS_ACTIONS.cartAddErrorMessage}
                        />
                      </div>
                    )}
                  </div>
                )}
              </AddToCartComponent>
            ) : null}
          </>
        )}
      </ModalContent>
    </Modal>
  )
}
