import { useCallback } from 'react'
import { useNProgress } from '../../../../lib/clientOnly/nprogress'
import type { UseMutationOptions } from '../../../useMutation'
import useMutation from '../../../useMutation'

export interface UseAutoshipApiMutationOptions<Args = any, Data = any, TransformedArgs = Args>
  extends UseMutationOptions<Data> {
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET'
  transformArgs?: (args: Args) => TransformedArgs
}

export function useAutoshipApiMutation<Args = any, Data = any>(
  path: string | ((args: Args) => string),
  {
    method = 'POST',
    transformArgs,
    onFinish,
    onStart,
    ...restOfOptions
  }: UseAutoshipApiMutationOptions = {}
) {
  const nProgress = useNProgress()

  const fn = useCallback(
    async (args: Args): Promise<Data> => {
      const url = `${typeof path === 'function' ? path(args) : path}`

      const res = await fetch(url, {
        method,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: args ? JSON.stringify(transformArgs ? transformArgs(args) : args) : undefined,
      })

      if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`)
      }

      return await res.json()
    },
    [method, path, transformArgs]
  )

  return useMutation(fn, {
    ...restOfOptions,
    onStart: () => {
      nProgress.inc()
      onStart?.()
    },
    onFinish: () => {
      nProgress.dec()
      onFinish?.()
    },
  })
}
