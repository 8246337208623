import clsx from 'clsx'
import { useCallback, useEffect, useState } from 'react'
import type { BigCommerceProductVariant as BigCommerceProductVariantV3 } from '../../../lib/serverOnly/api/bigcommerce/v3/product/types'
import { formatCurrency } from '../../../lib/utils/currency'
import { formatCurrencyWithSuperscriptCents } from '../../../lib/utils/formatCurrencyWithSuperscriptCents'
import calculateUnitPrice from '../../../lib/utils/pageType/product/calculateUnitPrice'
import { isVariantDiscontinued } from '../../../lib/utils/pageType/product/isVariantDiscontinued'
import type { DropdownOption } from '../../global/form/dropdown/OptionDropdown'
import { OptionDropdown } from '../../global/form/dropdown/OptionDropdown'

export type GenericOptionDropdownProps = {
  defaultVariant?: BigCommerceProductVariantV3
  handleChange?: (selectedOption: DropdownOption) => void
  isHighlighted?: boolean
  productVariantsV3: Array<BigCommerceProductVariantV3>
  purpleSelectionIndicator?: boolean
  useProductVariantIdAsValue?: boolean
  qaAttribute?: string
}

export default function GenericOptionDropdown({
  defaultVariant,
  handleChange,
  isHighlighted,
  productVariantsV3 = [],
  purpleSelectionIndicator = true,
  useProductVariantIdAsValue = false,
  qaAttribute = '',
}: GenericOptionDropdownProps) {
  const mapProductOption = useCallback(
    (variant) => {
      const isAvailable = variant?.purchasing_disabled !== true
      const isInStock = variant ? variant?.inventory_level > 0 : true
      const unitPrice = calculateUnitPrice(variant)

      return {
        name: variant?.option_values?.[0]?.label || '',
        label: `SKU: ${variant.sku || ''}`,
        sublabel: variant.price ? formatCurrencyWithSuperscriptCents(variant.price) : undefined,
        unitPrice: unitPrice ? formatCurrency(unitPrice) : undefined,
        value: String(!useProductVariantIdAsValue ? variant.option_values?.[0]?.id : variant?.id),
        available: isAvailable && isInStock,
      }
    },
    [useProductVariantIdAsValue]
  )

  const productOptions = productVariantsV3.reduce(
    (sanitizedOptions: Array<DropdownOption>, variant) => {
      if (!variant) return sanitizedOptions
      // do not display discontinued variants in radio group
      if (isVariantDiscontinued(variant)) return sanitizedOptions

      return sanitizedOptions.concat(mapProductOption(variant))
    },
    []
  )

  const productOptionsDropdownList = productOptions?.length
    ? [
        {
          options: productOptions,
        },
      ]
    : undefined

  const [selectOption, setSelectedOption] = useState(productOptionsDropdownList?.[0]?.options?.[0])

  useEffect(() => {
    if (defaultVariant) {
      setSelectedOption(mapProductOption(defaultVariant))
    }
  }, [defaultVariant, mapProductOption])

  const onVariantChange = useCallback(
    (selectedOption: DropdownOption) => {
      setSelectedOption(selectedOption)
      handleChange?.(selectedOption)
    },
    [handleChange]
  )

  return (
    <OptionDropdown
      inputName="productVariantSelector"
      isLoading={productVariantsV3.length === 0}
      onChange={onVariantChange}
      optionsList={productOptionsDropdownList}
      isHighlighted={isHighlighted}
      purpleSelectionIndicator={purpleSelectionIndicator}
      selectedOption={selectOption}
      renderButtonLabel={({ selectedOption, totalOptions }) => (
        <>
          <p
            className={clsx(
              'font-semibold',
              selectedOption?.available === false && 'text-coolGray-600 line-through'
            )}
          >
            {selectedOption?.name || 'Select an option...'}
          </p>
          {selectedOption ? (
            <>
              <p
                data-qa={qaAttribute}
                className={clsx(
                  'text-left text-sm',
                  selectedOption?.available === false && 'text-coolGray-600 line-through'
                )}
              >
                {selectedOption?.label}
              </p>
              <p className="text-left text-xs underline filter">See all {totalOptions} options</p>
            </>
          ) : null}
        </>
      )}
      renderOptionLabel={({ option }) => (
        <>
          <p className="text-xl font-bold">{option.name}</p>
          {option.label ? <p className="font-normal text-coolGray-800">{option.label}</p> : null}
          {option.sublabel ? (
            <p className="text-coolGray-800">
              <span className="text-xl font-medium">{option.sublabel}</span>
              {option.unitPrice ? (
                <span className="text-sm font-normal"> ({option.unitPrice}/ea)</span>
              ) : null}
            </p>
          ) : null}
        </>
      )}
    />
  )
}
