import clsx from 'clsx'
import debounce from 'lodash.debounce'
import uniqBy from 'lodash.uniqby'
import { formatCurrency } from '../../../../lib/utils/currency'
import { appendRegularPluralSuffix, getRegularPluralSuffix } from '../../../../lib/utils/grammar'
import AddToCartButton from '../../../pageType/account/AddToCartButton'
import { ProductBadge } from '../../../pageType/product/ProductBadge'
import type { Recommendation } from '../../nosto/types'
import { StarRating } from '../../StarRating'
import { useRecommendationsWidgetCardDimensions } from './config'
import { RecommendationsWidgetImage } from './RecommendationsWidgetImage'

/* Various Usecases to be handled:

[] // product.price

[ undefined ] // product.price

[ undefined, 1 ] // 1.00

[ 1, 9, 9 ] // 1.00 - 9.00

[ 9 ] // 9.00

[ 9, 1 ] // 1 - 9

[ 9, 9 ] // 9

[ 0, 9 ] // 0 - 9

[ 0 ] // 0
*/

function recommendationPriceRange(availableSkus: Recommendation['skus'], price: number) {
  const uniquePricedSkus = uniqBy(availableSkus, 'price')

  switch (uniquePricedSkus?.length) {
    case 0:
      return formatCurrency(price)
    case 1:
      return formatCurrency(uniquePricedSkus[0]?.price)
    default:
      return `${formatCurrency(uniquePricedSkus[0]?.price)} - ${formatCurrency(
        uniquePricedSkus[uniquePricedSkus.length - 1]?.price
      )}`
  }
}

export function RecommendationsWidgetCard({
  addToCart,
  recommendation,
  deal,
  productHref,
  className,
  cardWidths = null,
  cardMargins = null,
  productClickTracker,
  overrideClassName,
  useCardDimensions = true,
}: {
  addToCart?: boolean
  recommendation: Recommendation
  deal?: boolean
  productHref: string
  className?: string
  cardWidths?: {
    default: number
    sm: number
    md: number
  } | null
  cardMargins?: {
    default: number
    sm: number
    md: number
  } | null
  overrideClassName?: string
  productClickTracker?: (rec: Recommendation) => void
  useCardDimensions?: boolean
}) {
  const { width: cardWidth, margin: cardMargin } = useRecommendationsWidgetCardDimensions(
    cardWidths,
    cardMargins
  )

  const recordCardClick = debounce(
    () => productClickTracker && productClickTracker(recommendation),
    350,
    { leading: true, trailing: false }
  )

  const hiddenSkus = recommendation?.custom_fields?.hidden_skus
  // check if there are any available skus with a price
  const availableSkus = recommendation?.skus
    ?.filter((sku) => !hiddenSkus?.includes(sku.name) && sku.available && !isNaN(sku.price))
    ?.sort((a, b) => a.price - b.price)

  // determine recommendation price range
  const priceRange = recommendationPriceRange(availableSkus, recommendation.price)
  // determine amount of available variant options
  const variantOptionsCount = availableSkus?.length ?? 0

  return (
    <div
      className={clsx(
        overrideClassName || 'flex flex-col rounded-md border border-[#e5e7eb] bg-white sm:p-2',
        className
      )}
      style={
        useCardDimensions
          ? {
              width: `${cardWidth}rem`,
              minWidth: `${cardWidth}rem`,
              margin: `${cardMargin}rem`,
            }
          : {}
      }
    >
      <a href={productHref} className="relative flex flex-1 flex-col" onClick={recordCardClick}>
        <RecommendationsWidgetImage src={recommendation.image_url} alt={recommendation.name} />
        <div className="my-2 space-y-1.5 px-2 sm:my-8 sm:px-0">
          <div className="min-h-[3rem] sm:min-h-[4.5rem]">
            {recommendation.brand && (
              <p className="text-sm font-bold uppercase line-clamp-1 sm:text-base sm:line-clamp-2">
                {recommendation.brand}
              </p>
            )}
            <p className="text-sm line-clamp-2 sm:text-base">{recommendation.name}</p>
            {variantOptionsCount > 0 && (
              <p className="text-xs text-pebble-750 underline sm:text-sm">
                {variantOptionsCount} option{getRegularPluralSuffix(variantOptionsCount)}
              </p>
            )}
          </div>
          <p className="font-bold">{priceRange}</p>
          {recommendation.custom_fields?.reviews_yotpo_count &&
          recommendation.custom_fields?.reviews_yotpo_average ? (
            <StarRating
              rating={recommendation.custom_fields?.reviews_yotpo_average}
              count={recommendation.custom_fields?.reviews_yotpo_count}
              renderReviews={({ count }) => {
                return (
                  <p className="leading-0 text-primaryLegacy mt-2 text-xs font-semibold sm:ml-2 sm:mt-0">
                    {count} {appendRegularPluralSuffix('Review', count)}
                  </p>
                )
              }}
            />
          ) : null}
        </div>
        <ProductBadge isDeal={deal} />
      </a>
      {addToCart && (
        <div className="my-1 p-1 sm:my-0 sm:p-0">
          <AddToCartButton productId={recommendation.product_id} withMargin={false} />
        </div>
      )}
    </div>
  )
}
