import clsx from 'clsx'
import debounce from 'lodash.debounce'
import type { MouseEventHandler } from 'react'
import React, { useCallback, useRef } from 'react'
import { useBrowserLayoutEffect } from '../../../hooks/useBrowserLayoutEffect'
import range from '../../../lib/utils/range'
import { getIntersectionArea } from '../../../lib/utils/rectangle'

export const SLIDE_CLASS_NAME = 'slide'

export const ScrollerSlide = React.forwardRef<
  HTMLLIElement,
  {
    className?: string
    children?: React.ReactNode
    style?: React.CSSProperties
    onClick?: () => void
  }
>(({ children, className, style, onClick }, ref) => {
  return (
    <li ref={ref} className={clsx('scroll-snap-center', SLIDE_CLASS_NAME, className)} style={style}>
      {onClick ? (
        <button className="block h-full w-full" onClick={onClick}>
          {children}
        </button>
      ) : (
        children
      )}
    </li>
  )
})

type HorizontalScrollerProps = {
  className?: string
  style?: React.CSSProperties
  children?: React.ReactNode
  page?: number
  onPageChange?: (n: number) => void
  showScrollbar?: boolean
  direction?: 'vertical' | 'horizontal'
  scrollEnabled?: boolean
}

function getSlides(target: HTMLElement) {
  const slides = Array.from(target.getElementsByClassName(SLIDE_CLASS_NAME)) as HTMLDivElement[]
  return slides
}

function useSnapScroll({
  scrollRef,
  onPageChange,
  page,
}: {
  scrollRef: React.MutableRefObject<HTMLElement | null>
  onPageChange?: (n: number) => void
  page?: number
}) {
  // There are two ways the page changes - via scrolling or programmatically.
  // This ref is used to ensure there are no clashes between the two
  const pageRef = useRef<number>(0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScroll = useCallback(
    debounce(
      (e) => {
        const target = e.target as HTMLLIElement
        const slides = getSlides(target)
        const scroller = scrollRef.current

        if (scroller) {
          const slideVisibility = slides.map((slide) => {
            const slideRect = slide.getBoundingClientRect()
            const intersectionArea = getIntersectionArea(
              slideRect,
              scroller.getBoundingClientRect()
            )
            const slideArea = slideRect.width * slideRect.height
            const visibility = intersectionArea / slideArea
            return visibility
          })

          const nextPage = slideVisibility.findIndex((v) => v > 0.99)

          if (nextPage > -1) {
            pageRef.current = nextPage
            onPageChange?.(nextPage)
          }
        }
      },
      50,
      { trailing: true }
    ),
    [onPageChange]
  )

  useBrowserLayoutEffect(() => {
    const isStateDrivenPageChange = Boolean(pageRef.current !== page)

    if (page !== undefined && isStateDrivenPageChange) {
      const scroller = scrollRef.current
      if (scroller) {
        const slides = getSlides(scroller).slice(0, page)
        const left = slides.reduce((memo, s) => memo + s.clientWidth, 0)
        scroller.scrollTo({ left, behavior: 'smooth' })
      }

      pageRef.current = page
    }
  }, [scrollRef, page])

  return onScroll
}

function PageIndicator({
  onClick,
  selected,
  page,
}: {
  onClick: MouseEventHandler
  selected?: boolean
  page: number
}) {
  return (
    <button
      type="button"
      className={clsx(
        'focus:outline-none h-2.5 w-2.5 rounded-full',
        selected ? 'bg-gray-400' : 'border border-solid border-gray-400'
      )}
      onClick={onClick}
      aria-label={`Page ${page}`}
    />
  )
}

export function HorizontalScrollerPagination({
  numPages,
  page,
  onPageChange,
  className,
}: {
  numPages: number
  page: number
  onPageChange: (v: number) => void
  className?: string
}) {
  return (
    <div
      data-cw-cwp="HorizontalScrollerPagination"
      className={clsx('flex flex-row items-center justify-center space-x-2.5', className)}
    >
      {range(0, numPages).map((i) => {
        return (
          <PageIndicator
            page={i + 1}
            selected={i === page}
            key={i}
            onClick={() => onPageChange(i)}
          />
        )
      })}
    </div>
  )
}

const Scroller = React.forwardRef<HTMLDivElement, HorizontalScrollerProps>(
  (
    {
      className,
      style,
      onPageChange,
      children,
      page,
      showScrollbar = false,
      direction = 'horizontal',
      scrollEnabled = true,
    },
    ref
  ) => {
    const scrollRef = useRef<HTMLUListElement>(null)
    const onScroll = useSnapScroll({ scrollRef, onPageChange, page })

    return (
      <div data-cw-cmp="Scroller" ref={ref} className="w-full">
        <ul
          ref={scrollRef}
          onScroll={onScroll}
          style={style}
          className={clsx(
            {
              'overflow-x-auto': scrollEnabled && direction === 'horizontal',
              'overflow-x-hidden':
                (!scrollEnabled && direction === 'horizontal') || direction === 'vertical',
              'overflow-y-auto': scrollEnabled && direction === 'vertical',
              'overflow-y-hidden':
                (!scrollEnabled && direction === 'vertical') || direction === 'horizontal',
            },
            direction === 'horizontal'
              ? `scroll-snap-x-mandatory overscroll-x-contain`
              : `scroll-snap-y-mandatory overscroll-y-contain`,
            !showScrollbar && 'hide-scrollbar',
            className
          )}
        >
          {children}
        </ul>
      </div>
    )
  }
)

export default Scroller
