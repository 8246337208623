import useDynamicNostoRecommendations from '../../../lib/clientOnly/nosto/useDynamicNostoRecommendations'
import RecommendationsGrid from '../recommendations/RecomendationGrid/RecommendationGrid'
import type { RecommendationsWidgetProps } from '../recommendations/RecommendationsWidget/RecommendationsWidget'
import RecommendationsWidget from '../recommendations/RecommendationsWidget/RecommendationsWidget'
import { useNostoContext } from './NostoContext'
import type { Recommendation, Recommendations } from './types'

export default function NostoRecommendations({
  placement,
  dynamicPlacement,
  deal,
  layout = 'horizontal',
  ...rest
}: {
  placement: string
  dynamicPlacement?: boolean
  deals?: boolean
  layout?: 'horizontal' | 'vertical'
} & Omit<RecommendationsWidgetProps, 'title' | 'recommendations'>) {
  const { nostoLoaded, recommendationsLoaded, recommendations, productID, pageType } =
    useNostoContext()
  useDynamicNostoRecommendations(dynamicPlacement || false, placement)
  const recs: Recommendations = recommendations?.[placement]

  //This implements Nosto's View Product tracking on the recommendation
  //https://docs.nosto.com/techdocs/apis/js-apis/common-examples/record-attributions#method-documentation
  function nostoRecommendationClick(rec: Recommendation) {
    if (window.nostojs) {
      window.nostojs(function (api) {
        if (productID && pageType == 'PRODUCT') {
          //Call this if the click is coming from a Product Page
          api
            .defaultSession()
            .recordAttribution(
              'vp',
              rec.product_id.toString(),
              recs?.result_id,
              productID.toString()
            )
            .done()
        } else {
          //Use this if the click is coming from a non-product page
          api
            .defaultSession()
            .recordAttribution('vp', rec.product_id.toString(), recs?.result_id)
            .done()
        }
      })
    }
  }

  return (
    <div>
      {!dynamicPlacement && (!nostoLoaded || !recommendationsLoaded || !recs) ? (
        <div className="nosto_element hidden" id={placement}></div>
      ) : null}
      {layout === 'horizontal' ? (
        <RecommendationsWidget
          recommendations={recs}
          deal={deal}
          productClickTracker={nostoRecommendationClick}
          {...rest}
        />
      ) : (
        <RecommendationsGrid
          recommendations={recs}
          deal={deal}
          productClickTracker={nostoRecommendationClick}
          {...rest}
        />
      )}
    </div>
  )
}
